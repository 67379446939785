import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { SlEnvolope } from "react-icons/sl";
import { BsArrowRight } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaDribbble } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { PiTelegramLogoBold } from "react-icons/pi";
import { FaXTwitter } from "react-icons/fa6";
import "./footer.css";
import { NavLink } from "react-router-dom";
import { RiDiscordLine } from "react-icons/ri";
import vrclogo from '../../assets/logo.svg'
const Footer = () => {
  return (
    <>
    <div className="p-lg-0 p-3">
    <div className="join-community-section p-lg-5 p-3">
        <div className="container container-relative">
          <div className="row">
            <div className="col">
              <p className="text-center text-dark mb-0">BE ONE OF OUR MEMBER</p>
              <h2 className="text-dark text-center">JOIN OUR COMMUNITY</h2>
              <p className="text-center text-dark">
              Join our community and become a part of the decentralized crypto movement! At VRC Network, we're empowering individuals like you to turn dreams into reality through our innovative platform. Let's make your dreams come true, together.
              </p>
              <a href="https://t.me/VRC71official" target="blank">
              <button className="w-50 mx-auto d-flex justify-content-center bg-color p-2  border-0">
                <span className="btn_color">Join Our Telegram Channel</span>
              </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
      
      <section className="footer-bg p-lg-5 p-0">
        <div className="container pt-lg-0 pt-5">
          <div className="row p-lg-5 mt-5 p-0">
          <div className="col mt-lg-5">
              <img src={vrclogo} alt="" className="d-flex justify-content-center mx-auto text-center"/>
              <ul className="mb-0 mt-2 p-0">
                
          <p className="text-center footer_text">VRC network is a decentralized blockchain based POS(Proof of Stake) platform.</p>
               
                
                {/* <li className='mb-2' style={{listStyle: "none"}}><a className='text-decoration-none text-light' href="#">Mining</a></li> */}
              </ul>
            </div>
            <div className="col mt-lg-5 mt-3">
              <h3 className="change ms-4">Quick Links</h3>

              <ul className="mb-0 mt-3">
                <li className="mb-2" style={{ listStyle: "none" }}>
                  <a
                    className="text-decoration-none text-light"
                    href="https://vrccoin.com"
                    target="blank"
                  >
                    What is vrc?
                  </a>
                </li>

                <li className="mb-2" style={{ listStyle: "none" }}>
                  <NavLink
                    className="text-decoration-none text-light"
                    to="/referral"
                  >
                    Referral
                  </NavLink>
                </li>
                <li className="mb-2" style={{ listStyle: "none" }}>
                  <NavLink
                    className="text-decoration-none text-light"
                    to="/staking"
                  >
                    {" "}
                    Staking
                  </NavLink>
                </li>
               
                {/* <li className='mb-2' style={{listStyle: "none"}}><a className='text-decoration-none text-light' href="#">Mining</a></li> */}
              </ul>
            </div>
            <div className="col mt-lg-5">
              <h3 className="change ms-lg-4 text-center d-lg-none d-block">Links</h3>

              <ul className="mb-0 mt-lg-5 mt-0">
              <li className="mb-2" style={{ listStyle: "none" }}>
                  <NavLink
                    className="text-decoration-none text-light"
                    to="/levels"
                  >
                    Levels
                  </NavLink>
                </li>
                <li className="mb-2" style={{ listStyle: "none" }}>
                  <NavLink
                    className="text-decoration-none text-light"
                    to="/withdraw"
                  >
                    Withdraw
                  </NavLink>
                </li>
                <li className="mb-2" style={{ listStyle: "none" }}>
                  <NavLink
                    className="text-decoration-none text-light"
                    to="/promotion"
                  >
                    Promotion
                  </NavLink>
                </li>
              </ul>
            </div>
           
            <div className="col mt-lg-5">
              <h3 className="change ms-lg-4 ms-0">Developers</h3>

              <ul className="mb-0 mt-3">
                <li className="mb-2" style={{ listStyle: "none" }}>
                  <a className="text-decoration-none text-light" href="#">
                    Developer Portal <BsArrowUpRight className="ms-lg-3 ms-0" />
                  </a>
                </li>
                <li className="mb-2" style={{ listStyle: "none" }}>
                  <a className="text-decoration-none text-light" href="#">
                    {" "}
                    Platform
                  </a>
                </li>
                {/* <li className='mb-2' style={{listStyle: "none"}}><a className='text-decoration-none text-light' href="#">Scilla Language</a></li> */}
                <li className="mb-2" style={{ listStyle: "none" }}>
                  <a className="text-decoration-none text-light" href="#">
                    Explorer <BsArrowUpRight className="ms-lg-3 ms-0" />
                  </a>
                </li>
                {/* <li className='mb-2' style={{listStyle: "none"}}><a className='text-decoration-none text-light' href="#">Funding Opportunities</a></li> */}
              </ul>
            </div>
          
          </div>

          <div className="bottom-bar">
            <div className="bottom-bar-content">
              <div className="change mt-3 font_mobile">
             <a href="https://vzsolution.com/" target="_blank" className="fw-bold">
                Vzonesolutions
              </a>  © 2023. All Rights Reserved.
              </div>
             

              <div className="social-icons ms-lg-0 align-items-center responsive">
              <NavLink to="/privacy-policy">
                <div className="change font_mobile text-center">Privacy Policy</div>
              </NavLink>
                <span className="icons ms-4">
                  <a
                    href="https://www.facebook.com/vrcnetwork.offcial/
"
                    target="blank"
                  >
                    <FaFacebookF />
                  </a>
                </span>
                <br></br>
                <span className="icons">
                  <a href="https://twitter.com/VrcNetwork" target="blank">
                    {/* <FaTwitter /> */}
                    <FaXTwitter />

                  </a>
                </span>
                <br></br>
                <br></br>
                <span className="icons">
                  <a
                    href="https://www.instagram.com/vrcnetwork.official/"
                    target="blank"
                  >
                    <FaInstagram />
                  </a>
                </span>
                <br></br>
                <span className="icons">
                  <a href="https://t.me/VRC71official" target="blank">
                    <PiTelegramLogoBold />
                  </a>
                </span>
                <span className="icons ms-2">
                  <a href="#">
                    <RiDiscordLine className="fs-4" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
