import React from 'react'
import dragon from "../../assets/Dragon.gif"
import "./Loading.css"
function DataLoading() {

  return (
    <div className='overlay'>
        <div className=''>

      <img src={dragon} alt="dragon" width={500} />
        </div>
    </div>
  )
}

export default DataLoading
