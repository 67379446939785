import React, { useEffect, useState } from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";
import SwapModalA from "../staking/Modals/SwapModalA";
import "./refrel.css";
import {
  readContract,
  readRemoteContract,
  writeContract,
} from "../../instances/instance";
import { MdCopyAll } from "react-icons/md";
import { useAccount } from "wagmi";
import { zeroAddress } from "viem";
import { useNavigate } from "react-router";
import ref from "../../assets/new_ui/Referral 1 1.svg";
import DataLoading from "../../components/Loading/DataLoading";
import Comming from "../../components/comming/Comming";
import Skeleton from "react-loading-skeleton";

const Referral = () => {
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const [referralAddress, setRefferalAddress] = useState("");
  const [defaultReferralAddress, setDefaultReferralAddress] = useState("");
  const [userIsRegistered, setUserIsRegistered] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const [userIdUi, setUserIdUi] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    isShow: false,
  });
  const timeOut = () => {
    setTimeout(() => {
      setAlertMessage({
        message: "",
        isShow: false,
      });
    }, 2000);
  };
  const [loading, setLoading] = useState(false);

  const checkUserRegistration = async () => {
    getReferralLink();
    try {
      setLoading(true);
      const contractInstance = readContract();
      const userInfo = await contractInstance.userInfo(address);
      console.log("userInfouserInfo",userInfo);
      setLoading(false);
      if (userInfo.referrer !== zeroAddress) {
        setUserIsRegistered(true);
        setAlertMessage({
          message: "You haven't registered yet",
          isShow: false,
        });
      } else {
        setUserIsRegistered(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("error while checking user registration", error);
    }
  };

  useEffect(() => {
    if (isConnected && address) {
      checkUserRegistration();
      // getReferralLink();
    }
  }, [address, isConnected]);

  const registerUser = async () => {
    try {
      if (
        referralAddress == null ||
        referralAddress === "" ||
        referralAddress === undefined
      ) {
        setAlertMessage({
          message: "Please enter referral address / id",
          isShow: true,
        });
        timeOut();
        return;
      }
      setLoading(true);
      const writeContractInstance = await writeContract();
      const readContractInstance = readRemoteContract();
      const info = await writeContractInstance.userInfo(address);
      if (info.referrer !== zeroAddress) {
        setLoading(false);
        setAlertMessage({
          message: "Your account is already registered",
          isShow: true,
        });
        timeOut();
        return;
      }
      const defaultReferral = await readContractInstance.defaultReferral();
      let refAddress = referralAddress;
      if (refAddress.length !== 32) {
        let userUniqueIDAddress =
          await writeContractInstance.userUniqueIDAddress(referralAddress);
        refAddress = userUniqueIDAddress;
      } else {
        if (defaultReferral === refAddress) {
          refAddress = defaultReferral;
        }
      }

      const { referrer } = await writeContractInstance.userInfo(refAddress);
      if (referrer === zeroAddress && refAddress !== defaultReferral) {
        setAlertMessage({
          message: "Your referrer account is not registered",
          isShow: true,
        });
        setLoading(false);
        timeOut();
        return;
      }
      setLoading(true);
      const registerTrx = await writeContractInstance.register(refAddress);
      setAlertMessage({
        message: "Your account has registered",
        isShow: true,
      });
      await registerTrx.wait();
      setLoading(false);
      timeOut();
      navigate("/staking");
    } catch (error) {
      setLoading(false);
      console.error("error while register user", error);
    }
  };

  const getDefaultReferral = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const refId = urlParams.get("ref");
      if (refId) {
        setRefferalAddress(refId);
      } else {
        setRefferalAddress(null);
      }
    } catch (error) {
      console.error("error while get default referral", error);
    }
  };
  useEffect(() => {
    getDefaultReferral();
  }, []);
  function copyToClipboard(text) {
    // Check if Clipboard API is available
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        () => {
          setIsCopied(true); // Update state to show copied message
          setTimeout(() => setIsCopied(false), 2000); // Hide the message after 2 seconds
        },
        (err) => {
          console.error("Error copying text: ", err);
        }
      );
    } else {
      // Fallback for older browsers
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand("copy");
        console.log("Text successfully copied!");
      } catch (err) {
        console.error("Error copying text: ", err);
      }

      document.body.removeChild(textarea);
    }
  }
  const handleCopyClick = () => {
    copyToClipboard(userIdUi);
  };
  const getReferralLink = async () => {
    try {
      const writeContractInstance = await writeContract();
      const { referrer } = await writeContractInstance.userInfo(address);
      if (referrer === zeroAddress) {
        setAlertMessage({
          message: "You haven't registered yet",
          isShow: true,
        });
      } else {
        const userId = await writeContractInstance.userUniqueID(address);
        const urlParams = new URLSearchParams(window.location.search);
        const refId = urlParams.get("ref");
        if (refId) {
          copyToClipboard(`${window.location.href}`);
          // navigator.clipboard.writeText(`${window.location.href}`)
          setAlertMessage({
            message: `Refferal link is copy to clipboard ${window.location.href}`,
            isShow: true,
          });
        } else {
          copyToClipboard(`${window.location.href}?ref=${Number(userId)}`);
          // navigator.clipboard.writeText(`${window.location.href}?ref=${Number(userId)}`)
          // setAlertMessage({
          //   message: `Refferal link is copy to clipboard ${
          //     window.location.href
          //   }?ref=${Number(userId)}`,
          //   isShow: true,
          // });
          const link = `Refferal link is copy to clipboard:  ${
            window.location.href
          }?ref=${Number(userId)}`;
          setReferralLink(link);
          setUserIdUi(`${Number(userId)}`);
        }
      }
    } catch (error) {
      console.error("error while get referral link", error);
    }
  };

  useEffect(() => {
    // getReferralLink()
  }, [address, isConnected]);

  return (
    <>
      <div className="staking mb-5">
        <div className="stack_bgg">
          <div className="height_100">
            {loading && <DataLoading />}

            <Container className="mt-5">
              {/* <Comming /> */}
              <Row className="align-items-center">
                <div className="col-lg-6 col-12">
                  <h1 className="text-start mb-3 mt-5">
                    <span className="change">Referral</span>
                  </h1>
                  <p className="text-white ">
                    Join our platform and unlock the potential to earn unlimited
                    referral rewards through our innovative multi-level program!
                    With up to 15 levels of referral rewards available and each
                    account receiving triple rewards in total, there's no limit
                    to how much you can earn by referring others to our
                    platform.
                  </p>
                </div>
                <div className="col-lg-6 col-12">
                  <img src={ref} alt="" className="img-fluid" />
                </div>

                <Col className="mb-3">
                  {alertMessage.isShow && (
                    <Alert variant="warning">{alertMessage.message}</Alert>
                  )}
                  <div className="stakes_right_col mt-5 mb-5">
                    {/* {!userIsRegistered && (
                      <>
                        <div className="staking_inputwrap mb-3">
                          <input
                            className="staking_input"
                            type="text"
                            placeholder="Enter address/id here"
                            value={referralAddress}
                            onChange={(e) => setRefferalAddress(e.target.value)}
                          />
                        </div>
                        <div className="outer mt-3 center_mobile">
                          <button
                            className="button download_app mx-auto"
                            onClick={registerUser}
                          >
                            <div className="text"> Register </div>
                          </button>
                        </div>
                      </>
                    )}
                    {userIsRegistered && (
                      <div className="outer text-center mt-3 mb-3">
                        <span className="text-white text-center">
                           {referralLink}
                        </span>
                        <br/>
                        <span className="text-white text-center">
                        Your referral Id: <span className="change fw-bold">{userIdUi}</span>
                        </span>
                      </div>
                    )} */}

                    {isConnected ? (
                      userIsRegistered ? (
                        // User is connected and registered
                        <div className="outer text-center mt-3 mb-3">
                          <span className="text-white text-center">
                            {referralLink}
                          </span>
                          <br />
                          <span className="text-white text-center">
                            Your referral Id:{" "}
                            <span className="change fw-bold">
                              {userIdUi}
                              <MdCopyAll
                                className="fs-4 ms-2"
                                onClick={handleCopyClick}
                                style={{cursor:'pointer'}}
                              />
                              {isCopied && (
                                <span
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                >
                                  Copied!
                                </span>
                              )}
                            </span>
                          </span>
                        </div>
                      ) : (
                        // User is connected but not registered
                        <>
                          <div className="staking_inputwrap mb-3">
                            <input
                              className="staking_input"
                              type="text"
                              placeholder="Enter address/id here"
                              value={referralAddress}
                              onChange={(e) =>
                                setRefferalAddress(e.target.value)
                              }
                            />
                          </div>
                          <div className="outer mt-3 center_mobile">
                            <button
                              className="button download_app mx-auto"
                              onClick={registerUser}
                            >
                              <div className="text"> Register </div>
                            </button>
                          </div>
                        </>
                      )
                    ) : (
                      <div className="text-center mt-3 mb-3">
                        <Skeleton count={1} inline />
                        <Skeleton count={1} inline className="mt-3" />
                      </div>
                    )}
                  </div>
                </Col>
                {/* <SwapModalA /> */}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral;
