import React from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const EmojiPicker = ({
  showEmojiPicker,
  message,
  setMessage,
  setReactMessage,
  handleEmojiClick
}) => {
  const handleSetMessage = (value) => {
    if (showEmojiPicker) {
      setMessage((prevMessage) =>
        prevMessage === null || undefined
          ? value.native
          : prevMessage + value.native
      );
    } else {
      handleEmojiClick(value.native);
    }
  };
  return (
    <div className="emoji-picker-position">
      <Picker data={data} onEmojiSelect={handleSetMessage} />
    </div>
  );
};

export default EmojiPicker;
