import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAccount, useChainId } from "wagmi";
import { defaultChain } from "../../config";
import LancPad from "../../assets/new_ui/withdraw your VRC 2 1.svg";
import mem2 from "../../assets/new_ui/Wasting Reward 1.svg";
import mem2hover from "../../assets/new_ui/Wasting Reward  Black 1.svg";

import mem10 from "../../assets/new_ui/leavl 1.svg";
import mem10hover from "../../assets/new_ui/leavl_Black2 1.svg";

import wallet from "../../assets/new_ui/wallat1 1.svg";
import wallethover from "../../assets/new_ui/current  Reward  Black 1.svg";

import currReward from "../../assets/new_ui/Withdrawals 2 1.svg";
import currRewardHover from "../../assets/new_ui/Withdrawals  Black 1.svg";

import stake from "../../assets/new_ui/total Staking 1.svg"; 
import stakehover from "../../assets/new_ui/total Staking  Black 1.svg"; 

import "./lanchpad.css";
import Statement from "../statement/Statement";
import StakingReward from "./childComponents/StakingReward";
import LevelReward from "./childComponents/LevelReward";
import TotalWithdraw from "./childComponents/TotalWithdraw";
import WestingReward from "./childComponents/WestingReward";
import AllRewards from "./childComponents/AllRewards";
import WithdrawHistory from "./childComponents/WithdrawHistory";
import UserDetails from "./childComponents/UserDetails";
import Comming from "../../components/comming/Comming";
import { APIinstance } from "../../axios.config";
import ActiveTeamStake from "./childComponents/ActiveTeamStake";
const LanchPad = () => {
  const [refresh, setRefresh] = useState(false);
  const { address, isConnected } = useAccount();
  const chain = useChainId();
  const [percentage,setPercentage] = useState(0);

  const [userDetailss, setUserDetails] = useState(null);

  const [getActiveStake,setActiveStake] = useState(null)

  const getUserDetail = async () => {
    try {
      const { data } = await APIinstance.get(`withdraw/userStats/${address}`);

      console.log("datasdas of withdraw",data);
      setUserDetails(data);
    } catch (error) {
      console.error("error while get total users", error);
    }
  };

  const getActiveTeamStake = async () => {
    try {
      const  data  = await APIinstance.get(`withdraw/getActiveTeamStake/${address}`);
      // console.log("datadatadatadata",data,stakeActiveCoin,userLevelActiveCoins)
      // https://allv20.vrcscan.com/api/withdraw/getActiveTeamStake/0x834aF83B4A54c5eD9EE4796B699D3869fcBfA240
      console.log("datasdas",data,data?.userLevelActiveCoins);
      setActiveStake(data);
    } catch (error) {
      console.error("error while get total users", error);
    }
  };

  

  const [hover, setHover] = useState({
    stake: false,
    mem10: false,
    mem2: false,
    wallet: false,
    currReward: false,
  });

  // Handler to toggle hover state
  const handleHover = (image) => {
    setHover((prevHover) => ({ ...prevHover, [image]: !prevHover[image] }));
  };

  // const [remainingPercent , setRemainigPercent] = useState(0)
 
  // const getPercentage = (userDetails) => {
  //   if (userDetails) {
  //     const totalWithdrawal = userDetails.totalReward;
  //     const totalStack = userDetails.totalDeposit;
  //     const percentagees = totalWithdrawal / (totalStack * 3) * 100;
  //     const formatPercentage = Number(percentagees).toFixed(1);
  //     setPercentage(formatPercentage);
  //     const remainingPercentage = 100 - formatPercentage;
  //     setRemainigPercent(remainingPercentage)
  //     console.log("remainingPercentage",remainingPercentage);
  //   }
  // };

  const [remainingPercent, setRemainingPercent] = useState(100); // Assuming this state variable exists

const getPercentage = (userDetails) => {
  if (userDetails && userDetails.totalReward !== 0 && userDetails.totalDeposit !== 0) {
    const totalWithdrawal = userDetails.totalReward;
    const totalStack = userDetails.totalDeposit;
    const percentagees = totalWithdrawal / (totalStack * 3) * 100;
    const formatPercentage = Number(percentagees).toFixed(1);
    setPercentage(formatPercentage);
    const remainingPercentage = 100 - formatPercentage;
    setRemainingPercent(remainingPercentage);
  } else {
    setPercentage(0); 
    setRemainingPercent(100); 
  }
};


  
  useEffect(() => {
    if (isConnected && chain === defaultChain) {
      getUserDetail() ;
      getActiveTeamStake();
    }
  }, [isConnected && chain, address]);

  useEffect(() => {
    if (userDetailss) {
      getPercentage(userDetailss);
    }
  }, [userDetailss]);
  return (
    <section>
      <div className="staking p-lg-5 p-2">
        <div className="">
          <div className="container">
            <div className="row mt-5">
              {/* <Comming /> */}

              <div className="col-lg-6 col-sm-12 mt-lg-5 p-lg-5">
                <h1 className="text-lg-start text-center mb-3 mt-lg-5 mt-0">
                  <span className="change">WITHDRAW</span>
                </h1>
               
                <p className="text-light text-lg-start text-center">
                  You can withdraw your VRC any time, any where,
                  <br className="d-lg-block d-none" /> without any restriction.{" "}
                  <span className="">
                    The minimum withdrawal <br className="d-lg-block d-none" />{" "}
                    amount is 1 VRC. You just need to pay 1.5% fee in the{" "}
                    <br className="d-lg-block d-none" /> shape of VRC.
                  </span>
                </p>

                {/* <p className='text-light mt-lg-3 stak-sd responsive d-block'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur commodi suscipit reprehenderit delectus laboriosam. Reiciendis adipisci saepe beatae quod fuga, corporis, eaque pariatur officiis ipsa debitis iste illum quibusdam. Quaerat.</p> */}
              </div>

              <div className="col-lg-6 stak-sd col-sm-12 mb-lg-0 mb-md-0 mb-sm-5">
                <img
                  src={LancPad}
                  alt=""
                  className="d-flex justify-content-center mx-auto movein"
                  width={400}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-lg-0 pt-md-0 pt-5 px-lg-5 px-3 mb-5">
       
          <h1 className="text-center mt-5">
            <span className="change">ACCOUNT STATISTICS</span>
          </h1>
          <div className="progress-bar p-0 mx-auto">
            <div className="justify-content-between d-flex">
            <div
              className="progress-bar-fill justify-content-between"
              style={{ width: `${percentage}%` }}
            >
              <span className="progress-bar-text">
            Withdrawal  {`${percentage}%`} 
                {/* 23% */}
              </span>
             
            </div>
            <div className="progress-bar-text me-3">
             Remaining {`${remainingPercent}%`} 
                {/* 23% */}
              </div>
            </div>
           
          </div>
          <div className="row mt-3 justify-content-center">
          {userDetailss && <UserDetails userDetailss={userDetailss}  getActiveStake={getActiveStake}/>}

          {/* <div
            className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
            onMouseEnter={() => handleHover("stake")}
            onMouseLeave={() => handleHover("stake")}
          >
            <div className="cart m-0 step-res abut_cart" href="#">
              <div className="p-2">
                <div className="">
                  <img
                    className="d-flex justify-cotent-center mx-auto img-fluid"
                    src={hover.stake ? stakehover : stake}
                    alt=""
                  />
                </div>
                {userDetailss && (
                  <ActiveTeamStake
                  getActiveStake={getActiveStake}
                  refresh={refresh}
                  />
                )}
                <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
                Active Team Stake
                </h2>
              </div>
            </div>
          </div> */}

          <div
            className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
            onMouseEnter={() => handleHover("stake")}
            onMouseLeave={() => handleHover("stake")}
          >
            <div className="cart m-0 step-res abut_cart" href="#">
              <div className="p-2">
                <div className="">
                  <img
                    className="d-flex justify-cotent-center mx-auto img-fluid"
                    src={hover.stake ? stakehover : stake}
                    alt=""
                  />
                </div>
                {userDetailss && (
                  <StakingReward
                    userDetailss={userDetailss}
                    refresh={refresh}
                  />
                )}
                {/* <StakingReward refresh={refresh} /> */}
                <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
                  Staking Rewards
                </h2>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
            onMouseEnter={() => handleHover("mem10")}
            onMouseLeave={() => handleHover("mem10")}
          >
            <div className="cart m-0 step-res abut_cart" href="#">
              <div className="p-2">
                <div className="">
                  <img
                    className="d-flex justify-cotent-center mx-auto img-fluid"
                    src={hover.mem10 ? mem10hover : mem10}
                    alt=""
                  />
                </div>
                {userDetailss && (
                  <LevelReward userDetailss={userDetailss} refresh={refresh} />
                )}

                <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
                  Level Reward
                </h2>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
            onMouseEnter={() => handleHover("mem2")}
            onMouseLeave={() => handleHover("mem2")}
          >
            <div className="cart m-0 step-res abut_cart" href="#">
              <div className="p-2">
                <div className="">
                  <img
                    className="d-flex justify-cotent-center mx-auto img-fluid"
                    src={hover.mem2 ? mem2hover : mem2}
                    alt=""
                  />
                </div>
                {userDetailss && (
                  <WestingReward
                    userDetailss={userDetailss}
                    refresh={refresh}
                  />
                )}

                <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
                  Wasting Reward
                </h2>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
            onMouseEnter={() => handleHover("currReward")}
            onMouseLeave={() => handleHover("currReward")}
          >
            <div className="cart m-0 step-res abut_cart" href="#">
              <div className="p-2">
                <div className="">
                  <img
                    className="d-flex justify-cotent-center mx-auto img-fluid"
                    src={hover.currReward ? currRewardHover : currReward}
                    alt=""
                  />
                </div>
                {userDetailss && (
                  <TotalWithdraw
                    userDetailss={userDetailss}
                    refresh={refresh}
                  />
                )}

                <h2 className="text-center Vstack-title cole fs-lg-2 fw-bold">
                  Total Withdraw
                </h2>
              </div>
            </div>
          </div>
       
          <AllRewards refresh={refresh} setRefresh={setRefresh} />
          <WithdrawHistory refresh={refresh} />
        </div>
      </div>
    </section>
  );
};

export default LanchPad;
