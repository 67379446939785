import { Contract, ethers } from "ethers";
import contractAbi from "./abis/contractAbi.json";
import { contractAddress } from "./address";
import { formatEther } from "viem";
import { rpcURL } from "../config";



export const readRemoteContract = () => {
    try {
        const provider = new ethers.JsonRpcProvider(rpcURL);
        // let signer = await provider.getSigner();
        return new Contract(contractAddress, contractAbi, provider);
    } catch (error) {
        console.error("error while read remote contract", error);
    }
}
export const readContract = () => {
    try {
        let provider = new ethers.BrowserProvider(window.ethereum);
        return new Contract(contractAddress, contractAbi, provider);
    } catch (error) {
        console.error("errro while factory instance", error);
    }
}
export const writeContract = async () => {
    try {
        let provider = new ethers.BrowserProvider(window.ethereum);
        let signer = await provider.getSigner();
        return new Contract(contractAddress, contractAbi, signer);
    } catch (error) {
        console.error("errro while factory instance", error);
    }
}



export const getWalletBalance = async (address) => {
    try {
        let provider = new ethers.BrowserProvider(window.ethereum);
        let balance = await provider.getBalance(address);
        return formatEther(balance)
    } catch (error) {
        console.error("error while get wallet balance", error);
    }
}

export const getCurentBlock = async () => {
    try {
        const provider = new ethers.JsonRpcProvider(rpcURL);
        let block = await provider.getBlockNumber();
        return block
    } catch (error) {
        console.error("error while get current blokc", error);
    }
}

export const getBlockDetail = async (number) => {
    try {
        const provider = new ethers.JsonRpcProvider(rpcURL);
        let block = await provider.getBlock(number);
        return block;
    } catch (error) {
        console.error("error while get block detail", error);
    }
}


export const getBlock = async () => {
    try {
        const provider = new ethers.JsonRpcProvider(rpcURL);
        let block = await provider.getBlock();
        return block;
    } catch (error) {
        console.error("error while get block detail", error);
    }
}


