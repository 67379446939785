import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useChainId, useNetwork } from "wagmi";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import { defaultChain } from "../../config";
// import { ConnectButton } from '@rainbow-me/rainbowkit';
export default function ConnectWallatButton() {
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();

  return (
    //  <div className="outer">
    //     <div className="button">
    //         <span className="text" >
    //         </span>

    //     </div>
    // </div>
    // <ConnectButton className="text" />
    <button className="bg-transparent border-0">
      {isConnected ? (
        chain?.id === defaultChain ? (
          <div className="outer">
            <div className="button padd_moble">
              <span className="text" onClick={() => open()}>
                <MdOutlineKeyboardDoubleArrowDown className="fs-5 fw-bold text-white" />
                &nbsp;
                {address.slice(0, 4)}
                ...
                {address.slice(-4)}
              </span>
            </div>
          </div>
        ) : (
          <div className="outer">
            <div className="button">
              <span className="text" onClick={() => open()}>
                Wrong Netwrok
              </span>
            </div>
          </div>
        )
      ) : (
        <div className="outer">
          <div className="button">
            <span className="text" onClick={() => open()}>
              Connect wallet
            </span>
          </div>
        </div>
      )}
    </button>
  );
}
