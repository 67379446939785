import React, { useState } from "react";
import mem1 from "../../assets/new_ui/leavl 2 1.svg";
import memhover from "../../assets/new_ui/leavl_Black 3.svg";
import mem2 from "../../assets/new_ui/bank 1.svg";
import memhover2 from "../../assets/new_ui/bank Black 1.svg";
import wallet from "../../assets/new_ui/wallat1 1.svg";
import walletHover from "../../assets/new_ui/wallat_Black 1.svg";
import coinTop from "../../assets/new_ui/coin 2 1.svg";

const Card = ({ imgSrc, hoverImgSrc, title, description, noBorder, text }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardClass = `col-lg-4 col-md-6 col-sm-12 step-res ${noBorder ? "" : "border_right"}`;

  return (
    <div className={cardClass}
         onMouseEnter={() => setIsHovered(true)}
         onMouseLeave={() => setIsHovered(false)}>
      <div className="cart p-4">
        <div className="cart-content">
          <div className="cart-body">
            <img className="d-flex justify-content-center mx-auto img-fluid"
                 src={isHovered ? hoverImgSrc : imgSrc}
                 alt={title} />
            <h2 className="text-center fs-5 Vstack-title change">{title}</h2>
            <p className="cole black-on-hover text-center">{description}</p>
          </div>
          <div className="outer outland">
            <div className="btn_main">
              <a className="text-dark" target="_blank" rel="noopener noreferrer">
                <div className="text">{text}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};




const Step = () => {
  return (
    <div className="container mt-5 p-lg-0 p-5">
    <h1 className="change home-head text-center mt-5 pb-3">
      Start Staking with 3 Easy steps
    </h1>
    <div className="row mt-5 bg_cards before">
      <Card
        imgSrc={wallet}
        hoverImgSrc={walletHover}
        title="Choose a wallet"
        description="Connect your wallet & select VRC Mainnet to open the network selection menu."
        text="Explore wallets"
      />
      <Card
        imgSrc={mem2}
        hoverImgSrc={memhover2}
        title="Get VRC"
        description="Get Your VRC from Listed Exchanges."
        text="GET VRC"
      />
      <Card
        imgSrc={mem1}
        hoverImgSrc={memhover}
        title="Stake VRC"
        description="Transfer VRC Coin to VRC Network and start staking."
        noBorder={true} // This card will not have the border_right class
        text="Explore staking"
      />
    </div>
  </div>
  );
};

export default Step;
