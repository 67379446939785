import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import groot1 from "../../assets/new_ui/Level Web/level 1 1.svg";
import groot2 from "../../assets/new_ui/Level Web/Level-2.png";
import groot3 from "../../assets/new_ui/Level Web/Level-3.png";
import groot4 from "../../assets/new_ui/Level Web/Level-4.png";
import groot5 from "../../assets/new_ui/Level Web/Level-5.png";
import groot6 from "../../assets/new_ui/Level Web/Level-6.png";
import groot7 from "../../assets/new_ui/Level Web/Level-7-to-12.png";
import groot8 from "../../assets/new_ui/Level Web/Level-7-to-12.png";
import groot9 from "../../assets/new_ui/Level Web/Level-7-to-12.png";
import groot10 from "../../assets/new_ui/Level Web/Level-7-to-12.png";
import groot11 from "../../assets/new_ui/Level Web/Level-7-to-12.png";
import groot12 from "../../assets/new_ui/Level Web/Level-7-to-12.png";
import groot13 from "../../assets/new_ui/Level Web/Level-13-to-14.png";
import groot14 from "../../assets/new_ui/Level Web/Level-13-to-14.png";
import groot15 from "../../assets/new_ui/Level Web/Level-15.png";
import { defaultChain } from "../../config";
import { NavLink } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { LinkContainer } from "react-router-bootstrap";
import LevelCount from "./childComponents/LevelCount";
import { useAccount, useChainId } from "wagmi";
import { APIinstance } from "../../axios.config";

const Slider2 = () => {
  // console.log("asdasdas",totalUsers);
  const chain = useChainId();
  const { address, isConnected } = useAccount();
  const [slidesToShow, setSlidesToShow] = useState(3);

  const updateSlidesToShow = () => {
    if (window.innerWidth < 768) {
      setSlidesToShow(1);
    } else if (window.innerWidth < 1200) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(3);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateSlidesToShow);
    updateSlidesToShow();
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  const [allLevels, setAllLevels] = useState([]);
  console.log("allLevelsallLevelsallLevels",allLevels)

  
  useEffect(() => {
    if (isConnected && chain === defaultChain) fetchLevelDetail();
  }, [address, isConnected]);

  const fetchLevelDetail = async () => {
    try {
      const response = await APIinstance.get(`/withdraw/allLevelDetails/${address}`);
      console.log("response",response);
      setAllLevels(response.data); 
    } catch (error) {
      console.error("Failed to fetch level details:", error);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };
  const levelArr = [
    {
      levelNumber: 1,
      image: groot1,
    },
    {
      levelNumber: 2,
      image: groot2,
    },
    {
      levelNumber: 3,
      image: groot3,
    },
    {
      levelNumber: 4,
      image: groot4,
    },
    {
      levelNumber: 5,
      image: groot5,
    },
    {
      levelNumber: 6,
      image: groot6,
    },
    {
      levelNumber: 7,
      image: groot7,
    },
    {
      levelNumber: 8,
      image: groot8,
    },
    {
      levelNumber: 9,
      image: groot9,
    },
    {
      levelNumber: 10,
      image: groot10,
    },
    {
      levelNumber: 11,
      image: groot11,
    },
    {
      levelNumber: 12,
      image: groot12,
    },
    {
      levelNumber: 13,
      image: groot13,
    },
    {
      levelNumber: 14,
      image: groot14,
    },
    {
      levelNumber: 15,
      image: groot15,
    },
  ];
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(!refresh);
  }, [address]);

  // const mergedLevels = levelArr.map((staticLevel) => {
  //   const apiLevel = allLevels.find((level) => level.level === staticLevel.levelNumber);
  //   console.log("allLevels",allLevels);
  //   return { ...staticLevel, ...apiLevel };
  // });
  const mergedLevels = levelArr.map((staticLevel) => {
    const apiLevel = allLevels.find((level) => level && level.level === staticLevel.levelNumber) || { totalUsers: 0, totalStaked: 0, activeCoins: 0, activeCoinsPercent: 0 };
    return { ...staticLevel, ...apiLevel };
  });
  

  return (
    <div 
    className="mobile_slider d-flex justify-content-center"
    >
      {/* <Slider {...settings} className="container">
        {levelArr.map((item, index) => {
          return (
            <LevelCount
              key={index}
              index={index}
              item={item}
              refresh={refresh}
            />
          );
        })}
      </Slider> */}
       <Slider {...settings} className="container">
        {mergedLevels.map((item, index) => (
          <LevelCount
            key={index}
            index={index}
            item={item} 
            refresh={refresh}
            totalUsers={item.totalUsers}
            totalStaked={item.totalStaked}
            activeCoins={item.activeCoins}
            activeCoinsPercent={item.activeCoinsPercent}
          />
        ))}
      </Slider>
    </div>
  );
};

export default Slider2;
