import React from 'react'
import FrequentlyAccordion from '../frequentlyAccordion/FrequentlyAccordion'
import './home.css'
import Step from '../steps/Step';
import DetailsMember from '../detailsmember/DetailsMember';
import Secure from '../Secure/Secure';
// import Minting from '../Minting/Minting';
import Bonus from '../Bonus/Bonus';
import Community from '../community/Community'
import Banner from '../banner/Banner';
import Comming from '../comming/Comming';
const Home = () => {
    return (
        <>
           {/* <Comming /> */}
            <Banner />
            <DetailsMember />
            <Secure />
            {/* <Minting /> */}
            <Bonus />
            {/* <Community /> */}
            <Step />
            <FrequentlyAccordion />
        </>
    )
}

export default Home
