import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import wasting from "../../../assets/new_ui/current wasting Reward Reward 1.svg"; 
import wastinghover from "../../../assets/new_ui/current wasting Reward  Black 1.svg"; 

import crefferal from "../../../assets/new_ui/current Rafrull  Reward 1.svg"; 
import crefferalhover from "../../../assets/new_ui/current Rafrull  Reward  Black 1.svg"; 

import stakeimg from "../../../assets/new_ui/current stakig Reward 1.svg";  
import stakeimgHover from "../../../assets/new_ui/current stakig Reward  Black 1.svg"; 

import gift from "../../../assets/new_ui/wallat1 1.svg";
import gifthover from "../../../assets/new_ui/current  Reward  Black 1.svg"; 

import { APIinstance } from "../../../axios.config";
import { useAccount, useChainId } from "wagmi";
import { defaultChain } from "../../../config";
import { writeContract } from "../../../instances/instance";
import { parseEther } from "viem";
import Skeleton from "react-loading-skeleton";
import DataLoading from "../../../components/Loading/DataLoading";
import toast from "react-hot-toast";
function AllRewards({ refresh, setRefresh }) {
  const { address, isConnected } = useAccount();
  const chain = useChainId();

  const [loading, setLoading] = useState(false);
  const [allRewards, setAllRewards] = useState({
    stakeReward: null,
    levelReward: null,
    charityReward: null,
    totalReward: null,
    indexArr: null,
  });
  const getAllCurrentReward = async () => {
    try {
      setAllRewards({
        stakeReward: null,
        levelReward: null,
        charityReward: null,
        totalReward: null,
      });
      // setLoading(true)

      const {
        data: {
          stakeReward,
          levelReward,
          charityReward,
          totalReward,
          indexArr,
        },
      } = await APIinstance(`/withdraw/getRewardCalculation/${address}`);

      setAllRewards({
        stakeReward,
        levelReward,
        charityReward,
        totalReward,
        indexArr,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("error while get all current reward", error);
    }
  };
  useEffect(() => {
    if (isConnected && chain === defaultChain) getAllCurrentReward();
  }, [address, isConnected, chain]);
  const withdrawReward = async () => {
    try {
      setLoading(true);

      const {
        data: {
          totalReward,
          stakeReward,
          levelReward,
          charityReward,
          indexArr,
          signature,
          nonce,
        },
      } = await APIinstance(`/withdraw/getWithdrawSignature/${address}`);

      const writeContractInstance = await writeContract();
      const trx = await writeContractInstance.withdrawReward(
        parseEther(totalReward.toString()),
        parseEther(stakeReward.toString()),
        parseEther(levelReward.toString()),
        parseEther(charityReward.toString()),
        indexArr,
        nonce,
        signature
      );
      let { hash } = await trx.wait();
      getAllCurrentReward();
      setRefresh(!refresh);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("error while withdraw reward", error.message);
      if (error.response) {
        toast.error(error.response.data.message);
      }
    }
  };

  const [hover, setHover] = useState({
    stakeimg: false,
    crefferal: false,
    wasting: false,
    gift: false,
  });

  // Handler to toggle hover state
  const handleHover = (image) => {
    setHover((prevHover) => ({ ...prevHover, [image]: !prevHover[image] }));
  };
  return (
    <>
      {loading && <DataLoading />}
      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("stakeimg")}
        onMouseLeave={() => handleHover("stakeimg")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.stakeimg ? stakeimgHover : stakeimg}
                alt=""
              />
            </div>
            {allRewards.stakeReward === null ? (
              <div className=" d-flex justify-content-center">
                <Skeleton count={1} inline width={100} />
              </div>
            ) : (
              <CountUp
                className="text-center fs-2 fw-bold d-flex justify-content-center change"
                delay={2}
                end={allRewards.stakeReward}
                decimals={4}
              />
            )}
            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
              Current Stake Rewards
            </h2>
          </div>
        </a>
      </div>
      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("crefferal")}
        onMouseLeave={() => handleHover("crefferal")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.crefferal ? crefferalhover : crefferal}
                alt=""
              />
            </div>
            {allRewards.levelReward === null ? (
              <div className=" d-flex justify-content-center">
                <Skeleton count={1} inline width={100} />
              </div>
            ) : (
              <CountUp
                className="text-center fs-2 fw-bold d-flex justify-content-center change"
                delay={2}
                end={allRewards.levelReward}
                decimals={4}
              />
            )}
            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
              Current Referral Rewards
            </h2>
          </div>
        </a>
      </div>
      <div
        className="col-lg-3 col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("wasting")}
        onMouseLeave={() => handleHover("wasting")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.wasting ? wastinghover : wasting}
                alt=""
              />
            </div>
            {allRewards.charityReward === null ? (
              <div className=" d-flex justify-content-center">
                <Skeleton count={1} inline width={100} />
              </div>
            ) : (
              <CountUp
                className="text-center fs-2 fw-bold d-flex justify-content-center change"
                delay={2}
                end={allRewards.charityReward}
                decimals={4}
              />
            )}
            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
              Current Wasting Rewards
            </h2>
          </div>
        </a>
      </div>
      <div
        className="col-lg-3  col-md-6 col-12 mb-lg-0 mb-3"
        onMouseEnter={() => handleHover("gift")}
        onMouseLeave={() => handleHover("gift")}
      >
        <a className="cart m-0 step-res abut_cart" href="#">
          <div className="p-2">
            <div className="">
              <img
                className="d-flex justify-cotent-center mx-auto img-fluid"
                src={hover.gift ? gifthover : gift}
                alt=""
              />
            </div>
            {allRewards.totalReward === null ? (
              <div className=" d-flex justify-content-center">
                <Skeleton count={1} inline width={100} />
              </div>
            ) : (
              <CountUp
                className="text-center fs-2 fw-bold d-flex justify-content-center change"
                delay={2}
                end={allRewards.totalReward}
                decimals={4}
              />
            )}
            <h2 className=" text-center Vstack-title cole fs-lg-2 fw-bold">
              Current Rewards
            </h2>
          </div>
        </a>
      </div>
     
      <div className="container p-3 ms-lg-0 ms-0 bg_request mt-5 mb-5">
        <div className="row">
          <div className="col">
            <div className="below_btns">
              <h1 className="mx-auto fs-2 fon change fw-bold">
                Withdrawal Request
              </h1>
              <p className="mx-auto text-center">
                The money will credit to your account with in some seconds
                Please contact support if the withdrawal is not completed within
                some minutes.
              </p>

              <div>
                <div className="outer text-center d-flex justify-content-center w-100 mb-3">
                  <button
                    className="btn_main responsive w-100"
                    onClick={withdrawReward}
                  >
                    <div className="text text-dark"> Continue Withdraw </div>
                  </button>
                </div>
                {/* <Statement /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllRewards;
