import React, { useCallback, useEffect, useState } from "react";
import "./levelDe.css";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";
import { LinkContainer } from "react-router-bootstrap";
import { Form, Pagination, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import TotalLevelUser from "./childComponents/TotalLevelUser";
import TotalLevelStaked from "./childComponents/TotalLevelStaked";
import { useAccount, useChainId } from "wagmi";
import { readContract } from "../../instances/instance";
import { defaultChain } from "../../config";
import TeamMemberAddress from "./childComponents/TeamMemberAddress";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import ActiveInactiveMembers from "./childComponents/ActiveInactiveMembers";
import LevelMemberTable from "./childComponents/LevelMemberTable";
import { NavLink } from "react-router-dom";
import { APIinstance } from "../../axios.config";
import LevelCount from "./childComponents/LevelCount";
import Slider2 from "./Slider2";
import Skeleton from "react-loading-skeleton";
import { formatEther } from "ethers";
import UserStakingAmount from "../staking/ChildComponents/UserStakingAmount";
const LevelDetails = () => {
  const { level } = useParams();
  const navigate = useNavigate();
  const maxLevelNumber = 15;

  const currentLevelNumber = Number(level);
  const nextLevelNumber = currentLevelNumber + 1;
  const previousLevelNumber = currentLevelNumber - 1;

  const goToNextLevel = () => {
    if (currentLevelNumber < maxLevelNumber) {
      navigate(`/levelDetails/${nextLevelNumber}`);
    }
  };

  const goToPreviousLevel = () => {
    if (previousLevelNumber > 0) {
      navigate(`/levelDetails/${previousLevelNumber}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRowExpansion = (index) => {
    if (index === expandedRow) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const { address, isConnected } = useAccount();
  const chain = useChainId();
  const [totalUsers, setTotalUsers] = useState(null);
  const getTotalUsers = async () => {
    try {
      const contractInstance = readContract();
      let userCount = await contractInstance.userCount(address, level - 1);
      setTotalUsers(Number(userCount));
    } catch (error) {
      console.error("error while get total users", error);
    }
  };
  useEffect(() => {
    if (isConnected && chain === defaultChain) getTotalUsers();
  }, [isConnected && chain, address]);

  const [memberDetail, setMemberDetail] = useState({
    activeMember: null,
    inActiveMember: null,
    totalUsers: null,
    totalStaked: null,
  });
  const getActiveInactiveMemberList = async () => {
    try {
      setMemberDetail({
        activeMember: null,
        inActiveMember: null,
        totalUsers: null,
        totalStaked: null,
      });
      const {
        data: { activeMember, inActiveMember, totalStaked, totalUsers },
      } = await APIinstance(
        `/withdraw/getActiveInactiveMembes/${address}/${level}`
      );
      setMemberDetail({
        activeMember,
        inActiveMember,
        totalUsers,
        totalStaked,
      });
    } catch (error) {
      setMemberDetail({
        activeMember: null,
        inActiveMember: null,
        totalUsers: null,
        totalStaked: null,
      });
      console.error("error while get active and inactive member", error);
    }
  };
  useEffect(() => {
    if (isConnected && chain === defaultChain) getActiveInactiveMemberList();
  }, [address, isConnected]);

  const [teamMembers, setTeamMembers] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 0,
    siblingCount: 1,
  });

  const fetchTeamMembers = useCallback(
    async (page, limit) => {
      if (!isConnected || chain !== defaultChain) return;
      try {
        const response = await APIinstance.get(
          `/withdraw/levelUers?userAddress=${address}&level=${level}&page=${pagination.page}&limit=${pagination.limit}`
        );
        const data = response.data;
        const totalPages = Math.ceil(data.params.total / limit);
        getActiveInactiveMemberList()
        setTeamMembers(data.users);
        setPagination((prev) => ({ ...prev, totalPages: totalPages }));
      } catch (error) {
        setTeamMembers([])
        setMemberDetail({
          activeMember: null,
          inActiveMember: null,
          totalUsers: null,
          totalStaked: null,
        });
        console.error("Error fetching team members", error);
      }
    },
    [address, isConnected, chain, pagination.page, pagination.limit, level]
  );

  // useEffect(() => {
  //   fetchTeamMembers();
  // }, [fetchTeamMembers]);
  useEffect(() => {
    fetchTeamMembers(pagination.page, pagination.limit);
  }, [pagination.page, pagination.limit, fetchTeamMembers]);

  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  let startPage = Math.max(1, pagination.page - pagination.siblingCount);
  let endPage = Math.min(
    pagination.totalPages,
    pagination.page + pagination.siblingCount
  );
  let items = range(startPage, endPage).map((page) => (
    <Pagination.Item
      key={page}
      active={page === pagination.page}
      onClick={() => handlePageChange(page)}
    >
      {page}
    </Pagination.Item>
  ));

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleLimitChange = (e) => {
    const newLimit = Number(e.target.value);
    setPagination((prev) => ({ ...prev, page: 1, limit: newLimit }));
  };

  return (
    <section>
      <div className="container">
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div>
            <NavLink to="/levels" data-bs-toggle="tooltip" title="Back">
              <div className=" mt-5 px-3">
                <div className="">
                  <div className="backLevel">
                    <BsArrowLeft />
                  </div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="d-flex align-items-center gap-2 mt-5">
            <button
              onClick={goToPreviousLevel}
              className={`backLevel p-1 bg-transparent mt-lg-1 px-3 ${
                currentLevelNumber <= 1 ? "disabled-button" : ""
              }`}
              disabled={currentLevelNumber <= 1}
              data-bs-toggle="tooltip"
              title="Previous Level"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextLevel}
              className={`backLevel p-1 bg-transparent mt-lg-1 px-3 ${
                currentLevelNumber >= maxLevelNumber ? "disabled-button" : ""
              }`}
              disabled={currentLevelNumber >= maxLevelNumber}
              data-bs-toggle="tooltip"
              title="Next Level"
            >
              <IoIosArrowForward />
            </button>
          </div>
        </div>
        <div className="heroBanner">
          <div className="heroBannerContent">
            <span className="title change">Level {level && level}</span>
            <span className="subTitle">
              Staking offers various levels of rewards based on the amount of
              tokens you commit
            </span>
          </div>
        </div>
      </div>

      <div className="container ">
        <div className="row justify-content-between mt-2">
          <div className="col-lg-3 col-md-6 col-sm-12 ">
            <a className="cart m-0 abut_cart  step-res mb-3" href="#">
              <div className="p-2">
                <h2 className=" text-value">
                  <TotalLevelUser
                    index={level - 1}
                    totalUsers={memberDetail.totalUsers}
                  />{" "}
                </h2>
                <h2 className=" text-center Vstack-title change">
                  Total Members
                </h2>
              </div>
            </a>
          </div>
          <ActiveInactiveMembers
            level={level}
            activeMember={memberDetail.activeMember}
            inActiveMember={memberDetail.inActiveMember}
          />
          <div className="col-lg-3 col-md-6 col-sm-12 ">
            <a
              className="cart m-0 abut_cart  step-res mb-3 mb-lg-3 mb-md-3 "
              href="#"
            >
              <div className="p-2">
                <h2 className=" text-value">
                  <TotalLevelStaked
                    index={level - 1}
                    totalStaked={memberDetail.totalStaked}
                  />{" "}
                </h2>
                <h2 className=" text-center Vstack-title change">
                  Team Staked
                </h2>
              </div>
            </a>
          </div>
        </div>

        {/* <Table className="mt-3 mb-3" variant="dark" responsive>
          <thead>
            <tr>
              <th className="change">#</th>
              <th className="change">Address</th>
              <th className="change">Number of Stake</th>
              <th className="change">Total Deposit</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {totalUsers &&
              [...Array(totalUsers).keys()].map((item, index) => {
                return (
                  <>
                    <tr
                      onClick={() => toggleRowExpansion(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="change">{index + 1}</td>
                      <TeamMemberAddress level={level - 1} index={index} />
                      <td>
                        {expandedRow === index ? (
                          <MdOutlineExpandLess className="fs-3" />
                        ) : (
                          <MdOutlineExpandMore className="fs-3" />
                        )}
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan={12}>
                          <LevelMemberTable level={level - 1} index={index} />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
          </tbody>
        </Table> */}
        <Table className="mt-3 mb-3" variant="dark" responsive>
          <thead>
            <tr className="table_head">
              <th>#</th>
              <th>Address</th>
              <th>Number of Stake</th>
              <th>Total Deposit</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* {teamMembers.map((member, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => toggleRowExpansion(index)}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    {(pagination.page - 1) * pagination.limit + index + 1}
                  </td>
                  <td>{member.id || <Skeleton count={1} />}</td>
                  <td>
                    {member.depositLength !== undefined ? (
                      member.depositLength
                    ) : (
                      <Skeleton count={1} />
                    )}
                  </td>
                  <td>
                    {member.totalDepositedAmount !== undefined ? (
                      formatEther(member.totalDepositedAmount)
                    ) : (
                      <Skeleton count={1} />
                    )}
                  </td>
                  <td>
                    {expandedRow === index ? (
                      <MdOutlineExpandLess className="fs-3" />
                    ) : (
                      <MdOutlineExpandMore className="fs-3" />
                    )}
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr>
                    <td colSpan="5">
                      <LevelMemberTable level={level - 1} index={index} />
                    
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))} */}
            {/* {teamMembers.length > 0 && isConnected && chain === defaultChain
              ? teamMembers.map((member, index) => (
                  <tr key={index}>
                    <td>
                      {(pagination.page - 1) * pagination.limit + index + 1}
                    </td>
                    <td>{member.id}</td>
                    <td>{member.depositLength}</td>
                    <td>
                      {Number(formatEther(member.totalDepositedAmount)).toFixed(
                        4
                      )}
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          navigate(`/levelDetails/${level}/${member.id}`)
                        }
                        className="download_app width_custon"
                      >
                        View More
                      </button>
                    </td>
                  </tr>
                ))
              : [...Array(pagination.limit)].map((_, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))} */}
            {teamMembers.length > 0 && isConnected && chain === defaultChain
              ? teamMembers.map((member, index) => (
                  <tr key={index}>
                    <td>
                      {(pagination.page - 1) * pagination.limit + index + 1}
                    </td>
                    <td>{member.id}</td>
                    <td>{member.depositLength}</td>
                    <td>
                      {Number(formatEther(member.totalDepositedAmount)).toFixed(
                        4
                      )}
                    </td>
                    <td>
                      <button
                        disabled={
                          member.depositLength === "0" &&
                          member.totalDepositedAmount === "0"
                        }
                        onClick={() => {
                          if (
                            !(
                              member.depositLength === "0" &&
                              member.totalDepositedAmount === "0"
                            )
                          ) {
                            navigate(`/levelDetails/${level}/${member.id}`);
                          }
                        }}
                        className={`download_app width_custon ${
                          member.depositLength === "0" &&
                          member.totalDepositedAmount === "0"
                            ? "disabled-button-class"
                            : ""
                        }`}
                      >
                        View More
                      </button>
                    </td>
                  </tr>
                ))
              : [...Array(pagination.limit)].map((_, index) => (
                  <tr key={index}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>

        <div className="row align-items-center justify-content-center ">
          <div className="col-lg-6 col-6">
            <Form>
              <Form.Group
                controlId="limitSelect"
                className="d-lg-flex align-items-center gap-3 custom-select-wrapper"
              >
                <Form.Label className="change mb-0">Rows per page:</Form.Label>
                <Form.Control
                  as="select"
                  value={pagination.limit}
                  onChange={handleLimitChange}
                  className="custom-select-input"
                  style={{ width: "70px" }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="col-lg-6 justify-content-end text-end col-6 mt-4 pt-1">
            <Pagination size="md" className="justify-content-end text-end">
              <Pagination.First
                disabled={pagination.page === 1}
                onClick={() => handlePageChange(1)}
              />
              <Pagination.Prev
                disabled={pagination.page === 1}
                onClick={() => handlePageChange(pagination.page - 1)}
              />
              {items}
              <Pagination.Next
                disabled={pagination.page === pagination.totalPages}
                onClick={() => handlePageChange(pagination.page + 1)}
              />
              <Pagination.Last
                disabled={pagination.page === pagination.totalPages}
                onClick={() => handlePageChange(pagination.totalPages)}
              />
            </Pagination>
          </div>
        </div>
        {/* <div className="d-flex justify-content-end mb-4 gap-3 align-items-end">
          <button
            onClick={goToPreviousLevel}
            className="backLevel p-1 bg-transparent mt-3 px-3"
            data-bs-toggle="tooltip"
            title="Previous Level"
          >
            <BsArrowLeft />
          </button>

          <button
            onClick={goToNextLevel}
            className="backLevel p-1 bg-transparent mt-3 px-3"
            data-bs-toggle="tooltip"
            title="Next Level"
          >
            <BsArrowRight />
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default LevelDetails;
