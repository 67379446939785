import React, { useEffect, useState } from "react";
import { readRemoteContract } from "../../instances/instance";
import mem2 from "../../assets/new_ui/leavl 1.svg";
import mem1 from "../../assets/new_ui/Members 2 1.svg";
import wallet from "../../assets/new_ui/wallat1 1.svg";
import memhover from "../../assets/new_ui/Members Black 1.svg";
import memhover2 from "../../assets/new_ui/leavl_Black2 1.svg";
import walletHover from "../../assets/new_ui/wallat_Black 1.svg";
import coinTop from "../../assets/new_ui/coin 2 1.svg";

import { formatEther } from "viem";
import Skeleton from "react-loading-skeleton";
import { APIinstance } from "../../axios.config";

const Card = ({ imgSrc, hoverImgSrc, altText, detailText, title, noBorder }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardClass = `col-lg-4 col-md-6 col-sm-12 step-res ${noBorder ? "" : "border_right"}`;
  return (
    <div
    className={cardClass}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
  >
    <div className="cart mb-3">
      <img
        src={isHovered ? hoverImgSrc : imgSrc}
        alt={altText}
        className="d-flex justify-content-center mt-3 mx-auto img-fluid"
      />
      <div>
        {detailText ? (
          <p className="text-center change_hover mt-2 fs-2 fw-bold">

            {detailText}
          </p>
        ) : (
          <Skeleton count={1} inline width={200} className="text-center d-flex justify-content-center mx-auto mb-3"/>
        )}
        <h2 className="text-center Vstack-title change">{title}</h2>
      </div>
    </div>
  </div>
  );
};

const DetailsMember = () => {
  const [networkDetail, setNetworkDetail] = useState({
    totalUserInNetwork: null,
    totalStakeInNetwork: null,
    totalWithdrawInNetwork: null,
  });

  const getNetworkDetail = async () => {
    // ... same as before ...
    try {
      const readRemoteContractInstance = readRemoteContract();
      let totalUser = await readRemoteContractInstance.totalUserRegister();
      let totalStake = await readRemoteContractInstance.totalStakeInNetwork();
      let totalWithdrawInNetwork =
        await readRemoteContractInstance.totalWithdrawInNetwork();
      // const totalDetails = APIinstance.get('')
      setNetworkDetail({
        totalUserInNetwork: totalUser.toString(),
        totalStakeInNetwork: formatEther(totalStake),
        totalWithdrawInNetwork: formatEther(totalWithdrawInNetwork),
      });
    } catch (error) {
      // console.error("error while get network detail", error);
    }
  };

  useEffect(() => {
    getNetworkDetail();
  }, []);

  return (
    <div className="container mt-5 p-lg-0 p-5">
      <h1 className="change home-head text-center mt-5 pb-3">
        Network Statistics
      </h1>
      <div className="row mt-5 bg_cards before">
        <img src={coinTop} alt="" className="top_coin" />
        <Card
          imgSrc={mem1}
          hoverImgSrc={memhover}
          altText="All Members Icon"
          detailText={networkDetail.totalUserInNetwork}
          title="All Members In Network"
        />
        <Card
          imgSrc={mem2}
          hoverImgSrc={memhover2}
          altText="Total Staked Icon"
          detailText={
            networkDetail.totalStakeInNetwork &&
            Number(networkDetail.totalStakeInNetwork).toFixed(4)
          }
          title="Total Staked in Network"
        />
        <Card
          imgSrc={wallet}
          hoverImgSrc={walletHover}
          altText="Total Withdrawals Icon"
          detailText={
            networkDetail.totalWithdrawInNetwork &&
            Number(networkDetail.totalWithdrawInNetwork).toFixed(4)
          }
          title="Total Withdrawal in Network"
          noBorder={true}
        />
      </div>
    </div>
  );
};

export default DetailsMember;
