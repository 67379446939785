import React, { useState, useEffect } from "react";
import { UserRegisterChat } from "../axios.config";
import { PiSticker } from "react-icons/pi";
import stickersimg from '../assets/new_ui/stickers.png'

const Stickers = ({ showStickers, setShowStickers, handleSendMessage, showEmojiPicker, setShowEmojiPicker }) => {
    const [stickers, setStickers] = useState([]);

    const fetchStickers = async () => {
        try {
            const { data } = await UserRegisterChat.get("/stickers");
            setStickers(data);
        } catch (error) {
            console.log("Error in fetching stickers:", error);
        }
    };

    useEffect(() => {
        fetchStickers();
    }, []);

    const handleStickerClick = (e, sticker) => {
        e.preventDefault();

        console.log("Sticker clicked:", sticker);
        if (handleSendMessage) {
            handleSendMessage(e, sticker); 
        }
    };

    return (
        <div className="box_stickers">
            <button
                type="button"
                className="message-input-btn"
                onClick={() => {
                    setShowStickers(!showStickers)
                    setShowEmojiPicker(false);
                }}
            >
                <img src={stickersimg} alt="" style={{ height: '30px' }} />

            </button>

            {showStickers && (
                <div className="emoji-picker-position1 ">
                    {stickers.map((sticker, index) => (
                        <div
                            className="sticker-item"
                            key={index}
                            onClick={(e) => handleStickerClick(e, sticker)}
                        >
                            <img
                                src={`data:image/png;base64,${sticker?.sticker}`}
                                alt={`Sticker ${index}`}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Stickers;
