import React, { useEffect, useRef, useState } from "react";
import { UserRegisterChat } from "../axios.config";
import { useAccount } from "wagmi";

const ChatWidget = ({ isOpen }) => {
  const textRef1 = useRef(null);
  const { isConnected } = useAccount();
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    const getNotesWidget = async () => {
      try {
        const { data } = await UserRegisterChat.get("/notes");
        setNotes(data);

        if (textRef1.current) {
          const textWidth = textRef1.current.scrollWidth;
          const containerWidth = textRef1.current.offsetWidth;
          const animationDuration = (textWidth + containerWidth) / 50;

          const keyframes = [
            { transform: `translateX(${containerWidth}px)` },
            { transform: `translateX(-${textWidth}px)` },
          ];

          const options = {
            duration: animationDuration * 1000,
            iterations: Infinity,
            easing: "linear",
          };

          const animation = textRef1.current.animate(keyframes, options);

          return () => {
            animation.cancel();
          };
        }
      } catch (error) {
        console.log("error while fetching notes");
      }
    };

    getNotesWidget();
  }, [isOpen, isConnected]);

  return (
    <div>
      <div
        className={`text-slider p-2
      `}
      >
        <p ref={textRef1} className="text-white mb-0 d-flex">
          {notes.map((item, index) => (
            <React.Fragment key={item._id}>
              <span className="text-item mx-1">{item.note}{" "}</span>
            </React.Fragment>
          ))}
        </p>
      </div>
    </div>
  );
};

export default ChatWidget;
