import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsFillChatDotsFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdSend } from "react-icons/io";
import "./chat.css";
import EmojiPicker from "./EmojiPicker";
import { useAccount } from "wagmi";
import { UserRegisterChat } from "../axios.config";
import { writeContract } from "../instances/instance";
import InfiniteScroll from "react-infinite-scroll-component";
import socketIO from "socket.io-client";
import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-hot-toast";
import { CiEdit } from "react-icons/ci";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { FiSave } from "react-icons/fi";
import ChatWidget from "./ChatWidget";
import Stickers from "./Stickers";

function ChatModal() {
  const [show, setShow] = useState(false);
  const [newMessageCount, setNewMessageCount] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setNewMessageCount(0);
    markAllMessagesAsRead();
  };

  const [isOpen, setIsOpen] = useState(false);
  const { address, isConnected } = useAccount();
  const [message, setMessage] = useState(null);
  const [reactMessage, setReactMessage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showReactionEmojiPicker, setShowReactionEmojiPicker] = useState(false);
  const [checkStakeLenght, setCheckStakeLenght] = useState("");
  const [userMute, setUserMute] = useState(false);
  const [allMute, setAllMute] = useState(false);
  const [activeUsers, setActiveUsers] = useState(0);
  const [chat, setChat] = useState([]);
  const [userId, setUserId] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [messageIndex, setMessageIndex] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [userRegName, setUserRegName] = useState("");
  const [showStickers, setShowStickers] = useState(false);
  const [replyBox, setReplyBox] = useState(false);
  const [replyMessage, setReplyMessage] = useState(null);
  const [replyUsername, setReplyUsername] = useState(null);

  useEffect(() => {
    if (!isConnected) return;
    const socketConnection = socketIO.connect("https://chatbe.vrcscan.com");
    // const socketConnection = socketIO.connect(
    //   "https://sdwqxgxs-3300.inc1.devtunnels.ms"
    // );
    setSocket(socketConnection);
    if (socketConnection && isConnected) {
      socketConnection.on("user_stats", (data) => {
        setActiveUsers(data.activeUsersCount);
      });
    }
  }, [isConnected]);

  const toggleCard = () => {
    setIsOpen(!isOpen);
    setNewMessageCount(0);
    setShowEmojiPicker(false);
    markAllMessagesAsRead();
  };

  const handleSendMessage = (e, sticker) => {
    e.preventDefault();

    const trimmedMessage = message?.trim();
    if (trimmedMessage === "" && !sticker) {
      toast.error("Please Enter a Message");
      return;
    }

    let messageObj = {
      message: trimmedMessage,
      userId: userId,
      address: address,
      sticker: sticker,
    };

    if (!!replyMessage) {
      messageObj.replyId = replyMessage ? replyMessage?._id : null;
      messageObj.replyUserId = replyMessage?.user
        ? replyMessage?.user?._id
        : address;
    }

    socket.emit("chat_message", messageObj);

    setReplyBox(false);
    setReplyMessage(false);
    setReplyUsername(null);
    setShowStickers(false);
    setShowEmojiPicker(false);
    setMessage("");
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
    setShowReactionEmojiPicker(false);
    setShowStickers(false);
  };

  const toggleReactionPicker = (index) => {
    setMessageIndex(index);
    setShowReactionEmojiPicker(!showReactionEmojiPicker);
    setShowEmojiPicker(false);
    setShowStickers(false);
  };

  const handleEmojiClick = (emoji) => {
    let object = [...chat];
    object[messageIndex] = { ...object[messageIndex], emoji: emoji };
    setChat(object);
    setShowReactionEmojiPicker(false);
  };

  const checkUserRegistration = async () => {
    try {
      const { data } = await UserRegisterChat.post("/register", {
        userAddress: address,
      });
      setUserMute(data.user.isMute);
      setAllMute(data.isAllMute);
      setUserId(data.user._id);
      setUserRegName(data?.user?.userName);
    } catch (error) {
      console.log("error in checking registration");
    }
  };
  const fetchChatData = async (limit = 50) => {
    try {
      const { data } = await UserRegisterChat.get(
        `/chats?page=${page}&limit=${limit}`
      );
      if (data.params.totalPages === page) {
        setHasMore(false);
      }
      setChat([...chat, ...data?.chats]);
      setDeleteMessage(data.isDeleted);
      setPage(page + 1);
    } catch (error) {
      console.log("Error fetching chat data");
    }
  };

  useEffect(() => {
    checkUserRegistration();
    fetchChatData();
  }, [address, isConnected]);

  const checkUserStakeAmount = async () => {
    setLoading(true);
    try {
      const writeContractInstance = await writeContract();
      const { totalDeposit } = await writeContractInstance.userInfo(address);
      setCheckStakeLenght(totalDeposit);
    } catch (error) {
      console.log("Error in checking stake lenght");
    }
    setLoading(false);
  };

  useEffect(() => {
    checkUserStakeAmount();
  }, [address, isConnected]);

  useEffect(() => {
    if (socket && socket?.connected && isConnected) {
      socket.on("chat_message", (data) => {
        setChat([data, ...chat]);
      });
    }
  }, [
    socket?.connected,
    address,
    isConnected,
    chat,
    handleSendMessage,
    isOpen,
  ]);

  useEffect(() => {
    if (isOpen && socket && socket?.connected && isConnected) {
      socket.on("user_stats", (data) => {
        setActiveUsers(data.activeUsersCount);
      });
      socket.on(address, (data) => {
        setUserMute(data.isMute);
      });
      socket.on("all_user_muted", (data) => {
        setAllMute(data.isAllMute);
      });

      socket.on("all_user_unmuted", (data) => {
        setAllMute(data.isAllMute);
      });
      socket.on("deleted_user_chat", (data) => {
        const messageId = data;
        setChat((prevChat) =>
          prevChat.filter((item) => item._id !== messageId)
        );
      });
    }
    if (!isConnected) {
      if (socket && socket.connected) {
        socket.disconnect();
      }
    }
  }, [
    socket?.connected,
    address,
    isConnected,
    chat,
    handleSendMessage,
    isOpen,
  ]);

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const messageTime = new Date(timestamp);
    const diff = now - messageTime;
    const seconds = Math.floor(diff / 1000);
    if (seconds < 60) {
      return "just now";
    }
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes} min${minutes === 1 ? "" : "s"} ago`;
    }
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    }
    const days = Math.floor(hours / 24);
    return `${days} day${days === 1 ? "" : "s"} ago`;
  };

  useEffect(() => {
    if (!isOpen && socket && isConnected) {
      socket.on("chat_message", (data) => {
        if (data.address !== address) {
          setNewMessageCount((prevCount) => prevCount + 1);
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("chat_message");
      }
    };
  }, [isOpen, socket, isConnected, address]);

  const markAllMessagesAsRead = () => {
    const updatedChat = chat.map((msg) => ({ ...msg, read: true }));
    setChat(updatedChat);
  };

  const toggleReactionEmojiPicker = () => {
    setShowReactionEmojiPicker(!showReactionEmojiPicker);
  };

  const handleEditName = () => {
    setIsEditingName(true);
    setEditedName("");
  };

  const saveEditedName = async () => {
    setEditedName("");
    try {
      const { data } = await UserRegisterChat.put(`/updateName/${userId}`, {
        userName: editedName,
      });
      setIsEditingName(false);
    } catch (error) {
      console.log("error while fetching api");
      setIsEditingName(false);
      toast.error(error.response.data.message);
    }
  };
  const scrollToMessage = (messageId) => {
    const messageElement = document.getElementById(messageId);

    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      return toast.error("Chat is not Loaded yet");
    }
    messageElement.classList.add("color_chat_reply");
    setTimeout(() => {
      messageElement.classList.remove("color_chat_reply");
    }, 1500);
  };

  return (
    <>
      <button className="chat_icon d-lg-none d-block " onClick={handleShow}>
        <BsFillChatDotsFill className="fs-4" />
        {newMessageCount > 0 && !isOpen && (
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {newMessageCount > 99 ? "99+" : newMessageCount}
            <span className="visually-hidden">unread messages</span>
          </span>
        )}
      </button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          className="chat-box-header-mbl d-flex justify-content-between px-3 w-100"
          closeButton
        >
          <Modal.Title>
            <div className="d-flex justify-content-between">
              <div className="p-2 text-start">
                <span className="font_style fw-bold">V20 Network</span> <br />
                {isConnected && checkStakeLenght > 0 && (
                  <div>
                    {isEditingName ? (
                      <div className="edit-name-section">
                        <input
                          type="text"
                          value={editedName}
                          onChange={(e) => setEditedName(e.target.value)}
                          placeholder="Enter your name"
                          className="bg-transparent rounded outline-0 text-dark input_name p-1"
                        />
                        <button
                          onClick={saveEditedName}
                          className="bg-transparent border-0 outline-0 ms-1"
                        >
                          <FiSave />
                        </button>
                      </div>
                    ) : (
                      <p
                        onClick={handleEditName}
                        className="text-dark mb-0 align-items-center mt-1"
                      >
                        {userRegName != null
                          ? userRegName
                          : `${address?.slice(0, 4)}
          ...
          ${address?.slice(-4)}`}
                        <CiEdit
                          className="fs-5"
                          style={{ cursor: "pointer" }}
                        />
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="text-end mt-2">
                {isConnected && checkStakeLenght > 0 && (
                  <span className="font_Active">
                    <span className="text-success">( {activeUsers} )</span>
                  </span>
                )}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-100 p-0 chat-box-mobile">
          <div className="">
            {(showEmojiPicker || showReactionEmojiPicker) && (
              <EmojiPicker
                showEmojiPicker={showEmojiPicker}
                message={message}
                setMessage={setMessage}
                reactMessage={reactMessage}
                setReactMessage={setReactMessage}
                handleEmojiClick={handleEmojiClick}
                toggleEmojiPicker={toggleReactionEmojiPicker}
              />
            )}

            <ChatWidget isOpen={isOpen} />

            {isConnected && checkStakeLenght > 0 ? (
              <>
                <div id="scrollableDiv" className="chat-mbl-body">
                  <InfiniteScroll
                    dataLength={chat.length}
                    next={fetchChatData}
                    hasMore={hasMore}
                    style={{ display: "flex", flexDirection: "column-reverse" }}
                    inverse={true}
                    loader={
                      <div className="d-flex justify-content-center gap-2 text-center p-3">
                        {Array(3)
                          .fill()
                          .map(() => (
                            <div
                              className="spinner-grow spinner-grow-sm text-warning"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ))}
                      </div>
                    }
                    endMessage={
                      <p
                        style={{
                          textAlign: "center",
                          color: "black",
                          padding: "3px",
                        }}
                        className="change mt-3"
                      >
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    {chat?.map(
                      (item, index) =>
                        !item.isDeleted && (
                          <div
                            className={
                              address === item.address
                                ? "d-flex justify-content-end me-2"
                                : "d-flex mx-2 align-items-center gap-3"
                            }
                          >
                            <p key={index}></p>
                            <p
                              id={item._id}
                              className={
                                item.address === "Admin"
                                  ? "admin_message"
                                  : address === item.address
                                  ? "chatClient"
                                  : "chatAdmin"
                              }
                            >
                              {item?.replyTo?.user && item?.replyTo?.chat && (
                                <div
                                  className="chat-reply-ui mb-2"
                                  onClick={() =>
                                    scrollToMessage(item?.replyTo?.chat?._id)
                                  }
                                >
                                  <div className="mx-1 overflow-hidden">
                                    <span className="message_format">
                                      {item?.replyTo?.user?.userName != null
                                        ? item?.replyTo?.user?.userName
                                        : `${item?.replyTo?.user?.userAddress?.slice(
                                            0,
                                            4
                                          )}
                                  ...
                                  ${item?.replyTo?.user?.userAddress?.slice(
                                    -4
                                  )}`}
                                    </span>
                                    <br />
                                    <span className="message_format">
                                      {item?.replyTo?.chat?.message?.length > 25
                                        ? `${item?.replyTo?.chat?.message.substring(
                                            0,
                                            25
                                          )}... `
                                        : item?.replyTo?.chat?.message}{" "}
                                    </span>
                                  </div>
                                </div>
                              )}

                              <span className="d-flex justify-content-between">
                                <span className="font_address">
                                  {item.address === "Admin"
                                    ? "ADMIN"
                                    : `${item?.address?.slice(0, 4)}
                                    ...
                            ${item?.address?.slice(-4)}`}

                                  <span className="ms-3 fw-bold">
                                    {item?.user?.userName}
                                  </span>
                                </span>

                                <Dropdown>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="p-0 bg-transparent border-0 outline-0"
                                  >
                                    <IoMdArrowDropdownCircle />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="widt_drop">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setReplyBox(true);
                                        setReplyMessage(item);
                                        setReplyUsername(
                                          item?.user?.userName != null
                                            ? item?.user?.userName
                                            : item.address === "Admin"
                                            ? "ADMIN"
                                            : `${item?.address?.slice(
                                                0,
                                                4
                                              )} ... ${item?.address?.slice(
                                                -4
                                              )}`,
                                          item._id
                                        );
                                      }}
                                    >
                                      Reply
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </span>

                              {!!item.message ? (
                                item?.message
                              ) : (
                                <img
                                  src={`data:image/png;base64,${item?.sticker?.sticker}`}
                                  alt={`Sticker ${index}`}
                                  className="d-flex mx-auto"
                                  style={{ height: "120px" }}
                                />
                              )}
                              <p className="text-end d-flex mb-0 justify-content-end">
                                {" "}
                                <span className="font_address">
                                  {formatTimestamp(item.timestamp)}
                                </span>
                              </p>

                              {item.emoji && (
                                <span className="emoji-reactions">
                                  {item.emoji}
                                </span>
                              )}
                            </p>
                            <button
                              className="bg-emjos p-2 d-none rounded-circle border-0 outline-none"
                              onClick={() => toggleReactionPicker(index)}
                            >
                              😊
                            </button>
                          </div>
                        )
                    )}
                  </InfiniteScroll>
                </div>
                <div className="chat-input">
                  <form onSubmit={handleSendMessage}>
                    <div className="input-wrapper">
                      {userMute ? (
                        <p
                          className="text-dark text-center mt-2 mb-2"
                          style={{ margin: "0 auto" }}
                        >
                          Chat is muted by admin
                        </p>
                      ) : allMute ? (
                        <p
                          className="text-center mt-2 mb-2"
                          style={{ margin: "0 auto" }}
                        >
                          Chat is muted for all users by admin
                        </p>
                      ) : (
                        <div className="w-100">
                          {replyBox && (
                            <div className="reply_box bg-white w-100">
                              <div className="d-flex justify-content-between">
                                <p className="text-dark mb-0">
                                  {replyUsername}
                                </p>
                                <AiOutlineClose
                                  onClick={() => setReplyBox(false)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <p className="text-dark mb-0 message_format">
                                {replyMessage.message}
                              </p>
                            </div>
                          )}

                          <div className="d-flex">
                            <button
                              type="button"
                              className="message-input-btn"
                              onClick={toggleEmojiPicker}
                            >
                              😊
                            </button>
                            <Stickers
                              showStickers={showStickers}
                              setShowStickers={setShowStickers}
                              handleSendMessage={handleSendMessage}
                              showEmojiPicker={showEmojiPicker}
                              setShowEmojiPicker={setShowEmojiPicker}
                            />

                            <input
                              id="chat-input"
                              placeholder="Send a message..."
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                            <button className="message-input-btn">
                              <IoMdSend className="material-icons" />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </>
            ) : !isConnected ? (
              <div className="chat-box-body-loading bg-transparent p-2 text-center change home-head mt-5 fs-5 align-items-center mt-5 pt-5">
                Please Connect your Wallet to view Chat Box
              </div>
            ) : (
              <>
                {!loading ? (
                  <div className="chat-box-body-loading p-2 change home-head text-center fs-5 align-items-center mt-5 pt-5">
                    Your stake amount is not enough to access the chat box.
                  </div>
                ) : (
                  <div className="chat-box-body-loading gap-3 text-center fs-5 align-items-center mt-5 pt-5">
                    {Array(3)
                      .fill()
                      .map(() => (
                        <div
                          className="spinner-grow spinner-grow-md text-warning"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChatModal;
