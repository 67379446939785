import React from "react";
import "./frequenltyAccordion.css";
import faq from '../../assets/new_ui/faq_b 1.svg'

import Accordion from "react-bootstrap/Accordion";
const accordionData = [
  {
    title: "What is staking?",
    key: 1,
    detail:
      "Staking is the process of participating in a blockchain network by locking up a cryptocurrency's tokens as collateral to support network operations and, in return, earning rewards or interest.",
  },
  {
    key: 2,
    title:
      "How does staking benefit me?",
    detail:
      "Staking can offer several benefits, depending on the specific blockchain or cryptocurrency platform you're participating in. It allows you to earn rewards or interest on your cryptocurrency holdings.",
  },
  {
    key: 3,
    title: "What is a seed node? ",
    detail:
      "Seed nodes help users discover and connect to other nodes in the network, facilitating the peer-to-peer communication necessary for the blockchain to function.",
  },
  {
    key: 4,
    title: "What is a Staked Seed Node Operator (SSN)?",
    detail:
      "A Staking Seed Node Operator (SSN) is an entity or individual responsible for operating and maintaining seed nodes specifically for a blockchain network's staking ecosystem. These nodes play a crucial role in enabling stakers to participate in the network and receive rewards.",
  },
];
const accordionDataTwo = [
  {
    key: 5,
    title: "What happens if a SSN operator fails?",
    detail:
      "If a Staking Seed Node (SSN) operator fails or their node goes offline, it can temporarily disrupt stakers' ability to connect to the network, potentially impacting rewards and the overall stability of the staking ecosystem.",
  },
  {
    key: 7,
    title: "Can I switch SSN providers?  ",
    detail:
      "Yes, in many blockchain networks, you can switch Staking Seed Node (SSN) providers if you're staking your tokens through a service that offers multiple SSN options.",
  },
  {
    key: 8,
    title: "Why is there a minimum lock-up period for my stake? ",
    detail:
      "A minimum lockup period for staking is typically imposed to incentivize long-term commitment to the network, enhance security, and discourage short-term speculative behavior. It benefits the network by promoting stability and discouraging frequent entry and exit, which can disrupt the consensus mechanism.",
  }
];

function FrequentlyAccordion() {
  return (
    <div className="container">
      <div className="row justify-content-center mt-lg-5 mt-2 mb-lg-5 mb-2">
        <div className="col-lg-8 col-md-12 col-sm-12 mt-lg-5 mt-2" >
          
        </div>
        <div className="col-lg-5 col-md-12 mt-3">
          <div className="text-center">
            {/* <img src={Line} alt="" /> */}
          </div>
        </div>
        <div className="row justify-content-center align-items-center mt-lg-5 mt-2 mb-5">
        <div className="col-lg-6 col-md-12 col-sm-12">
           <img src={faq} alt="" className="img-fluid" height={700}/>
           </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
          <h1 className="Text-heading text-start change fw-bold">
            Frequently Asked Questions
          </h1>
            {accordionData.map((data, index) => {
              return (
                <>
                  <div 
                    className="col-lg-12 col-md-12 col-sm-12 mt-3"
                    key={index}
                  >
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className="accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body className="text-white">{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
             {accordionDataTwo.map((data) => {
              return (
                <>
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <Accordion className="accordion-bg">
                      <div className="col-md-12">
                        <Accordion.Item
                          eventKey={data.key}
                          className=" accordion-bg"
                        >
                          <Accordion.Header className=" accordion-bg">
                            {data.title}
                          </Accordion.Header>
                          <Accordion.Body className="text-white">{data.detail}</Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </Accordion>
                  </div>
                </>
              );
            })}
          </div>

        
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAccordion;
