import React, { useEffect, useState } from 'react'
import { useAccount, useChainId } from 'wagmi'
import { readContract } from '../../../instances/instance';
import { defaultChain } from '../../../config';
import CountUp from 'react-countup';
import { formatEther } from 'viem';

function StakingReward({refresh,userDetailss}) {
    const { address, isConnected } = useAccount();
    const chain = useChainId();
    const [stakingReward, setStakingReward] = useState(null);
    // const getTotalUsers = async () => {
    //     try {
    //         const contractInstance = readContract();
    //         let userTotalInvestRewardWithdraw = await contractInstance.userTotalInvestRewardWithdraw(address);
    //           setStakingReward(formatEther(userTotalInvestRewardWithdraw))
    //     } catch (error) {
    //         console.error("error while get total users", error);
    //     }

    // }
    // useEffect(() => {
    //     if (isConnected && chain === defaultChain)
    //         getTotalUsers()
    // }, [isConnected && chain, refresh, address])
    return (
        <>
            <CountUp className="text-center fs-2 fw-bold d-flex justify-content-center change" delay={2} end={userDetailss.stakingReward} decimals={4} />
        </>
    )
}

export default StakingReward
