import React from "react";

const Privacy = () => {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-lg-12">
          <h1 className="text-white fs-1 text-center change home-head ">
            VRC Network Terms of <br className="d-lg-flex d-none"/> Service  Agreement
          </h1>

          <div className="px-4 mb-5 clr_privacy">
            <h3 className="mt-3 change fw-bold">
              Last Updated: 11 November 2023
            </h3>
            <p>
              VRC Network (hereinafter referred to as, “VRC”, “Vzone Solutions”
              “the Company”, “ourselves”, “we” or “us”). We offer you the use of
              VRC Network mobile wallet (the “VRC mainnet wallet”, “VRC
              Wallet”), subject to this Term of Service Agreement (terms and
              conditions), VRC privacy policy and/or other agreement(s) or
              polic(ies) in relation to VRC and/or our service(s).
            </p>
            <p>
              THIS AGREEMENT CONTAINS THE TERMS AND CONDITIONS THAT GOVERN YOUR
              ACCESS TO USE OF THIS VRC MAINNET WALLET AND/OR OUR SERVICES, AND
              IS AN AGREEMENT BETWEEN YOU OR THE ENTITY YOU REPRESENT (“YOU” OR
              “YOUR”). PLEASE READ THE AGREEMENT CAREFULLY BEFORE USING OUR
              WALLET OR OUR SERVICE, AND REVIEW THEM PERIODICALLY AND REGULARLY
              FOR CHANGES.
            </p>
            <p>
              PLEASE NOTE THAT WE RESERVE THE RIGHT, AT OUR SOLE DISCRETION, TO
              REVISE, AMEND, UPDATE, DELETE PART OR THE ENTIRE THE AGREEMENT, AT
              ANY TIME WITHOUT NOTICE.
            </p>
            <h3 className="mt-3 change fw-bold">DEFINITIONS</h3>
            <p>
              1.1 “API” means an application program interface.
              {/* <a href="mailto:support@v20.network">
                <span className="change">support@v20.network</span>
              </a> */}
              .
            </p>
            <p>
              1.2 “Digital Asset” means all class of digitalized assets
              including crypto assets, cryptocurrencies, virtual assets,
              blockchain-based assets, digital tokens, virtual tokens, etc., but
              excluding any government issued digital assets or currency, or any
              digital assets deemed as securities in any competent
              jurisdictions.
            </p>
            <p>
              1.2.1 “Wallet” means a digital assets wallet stores private keys
              and intend to keep digital assets safe and accessible, and
              allowing owner to send and receive digital assets.
            </p>
            <p>
              1.2.2 “Mnemonic Words” means a set of random words generated by a
              Wallet at the time of set up, which allow a User to set up the
              same Wallet again on a different device, phone or browser, or to
              recover User’s Private Key.
            </p>
            <p>
              1.2.3 “Personal Information” means information recorded in
              electronic or any other form which may identify a person when used
              alone or in combination with other information, including name,
              date of birth, identification card number, passport number,
              address, telephone number, bank or credit card number, e-mail
              address , mobile device information, IP address, transaction
              record.
            </p>
            <p>
              1.2.4 “Privacy Policy” means the privacy policy of VRC Network and
              its associated or affiliated entities updated from time to time.
            </p>
            <p>
              1.2.5 “Private Key” is a secret number that is used in
              cryptography which used to verify transactions and prove ownership
              of a blockchain address.
            </p>
            <p>
              1.2.6 “User”, “your”, “you”, “he/she” or “his/her” means the
              individual or entity using or accessing this VRC Wallet or using
              any Service(s) provided by the Company.
            </p>
            <h3 className="mt-3 change fw-bold">SERVICE</h3>
            <p>
              2.1 Smartphone or mobile phone application wallet (“Mobile
              Wallet”), with VRC Blockchain Mainnet added in it.
            </p>
            <p>
              For the avoidance of doubt, the Service provided by VRC wallet may
              enable User to send and receive, withdraw, stake or in any manner
              transact digital asset (“Digital Asset Transactions”)
            </p>
            <p>
              2.2 VRC Network application. User agrees and acknowledges, in
              relation to VRC Network, that:
            </p>
            <p>
              2.2.1 VRC Wallet only supports those digital assets(s) made
              available by VRC Network and the User may only use VRC Network
              Application to receive, transfer, store, or in any manner manage
              the digital assets supported by VRC Network.
            </p>
            <p>
              2.2 VRC Network Wallet may allow user to create password, setup
              biometric identification, for login to the User’s VRC wallet
              and/or to receive, transfer, authorize, approve any transaction of
              digital assets by signing Private Key with your VRC Wallet. The
              User shall have the sole responsibility to safeguard and backup
              its password, biometric identification, or pattern lock, and VRC
              Network or Vzone solutions shall not have any liability arising
              from any loss, misuse, unauthorized use, stolen, delete or hacking
              activities of the password, biometric identification, or pattern
              lock. For the avoidance of doubt, VRC Network does not have any
              responsibility to assist the User to store or recover his/her
              Private Keys, passwords or Mnemonic Words so as to recover his/her
              Wallets, or to report or freeze any stolen, hacked or unauthorized
              use of Wallets.
            </p>
            <p>
              2.2.3 Vzone Solutions may from time to time provides update or
              upgrade to VRC Wallet, but is not under obligation to do so, and
              that the User shall proactively update the VRC Network Wallet for
              its optimal function.
            </p>
            <p>
              2.2.4. The User shall refer to blockchain for the authentic and
              accurate records of all the transactions.
            </p>
            <p>
              2.3 Personal Information. During your use of our Service, we will
              not ask for your Personal Information. We will not ask for any
              permissions when you install and use VRC wallet other than the
              camera permission. We are asking for camera permission just for
              the ease of QR code scanning so that user can made transaction
              easily by scanning the public address QR code. However, it is not
              mandatory for any user to give this permission while using VRC
              wallet.
            </p>

            <h3 className="mt-3 change fw-bold">
              YOUR RESPONSIBILITY AND OBLIGATION
            </h3>
            <p>
              3.1 Your Wallets. You shall be responsible for all activities of
              your Wallet(s), regardless of whether the activities are
              authorized by you or undertaken by you, your agent, staffs,
              contractors, or any other persons (if applicable). VRC Network is
              not responsible for any unauthorized access to your Wallet,
              including any access occurred as a result of hacking activity
              arise from the fault on your part, fraud, phishing or any other
              criminal activity perpetrated by any other persons. You shall also
              be responsible to ensure that your use of the Wallet does not
              violate any applicable laws in a competent jurisdiction.
            </p>
            <p>
              3.2 Security and Backup. You shall be solely responsible for
              properly configuring and using the Service, in particularly your
              VRC Wallets, and taking appropriate action to secure, protect and
              backup your Wallets in a manner that will provide appropriate
              security and protection, which include your obligations to
              securely maintain any passwords, pattern lock or Mnemonic Words of
              your Wallets. You assure that you will not share with any other
              person, including VRC Network or any of our staffs, agents or
              contractors, any passwords, pattern lock or Mnemonic Words of your
              Wallets, and we shall not be held responsible for the security
              usage of your Wallets.
            </p>

            <h3 className="mt-3 change fw-bold">MISCELLANEOUS</h3>

            <p>
              4.1 Severability. If any portion of this Agreement is held to be
              invalid or inoperative, the remaining portions of this Agreement
              will remain in full force and effect. Any invalid or inoperative
              portions will be interpreted to effect and intent of the original
              portion. If such construction is not possible, the invalid or
              inoperative portion will be severed from this Agreement but the
              rest of the Agreement will remain in full force and effect.
            </p>
            <p>
              4.2 Amendments. VRC Network reserves the right, at its sole
              discretion, to change, add or remove portions of these Agreement,
              at any time. It is your responsibility to review the amended
              Agreement. Your continued use of the VRC wallet following the
              posting of changes will mean that you accept and agree to the
              changes and you agree that all subsequent transactions by you will
              be subject to these Agreement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;