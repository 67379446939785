import React from "react";
import ReactDOM from "react-dom/client";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const vrcchain = {
  id: 713,
  name: "VRC Chain",
  network: "VRC Chain",
  nativeCurrency: {
    decimals: 18,
    name: "VRC Chain",
    symbol: "VRC",
  },
  rpcUrls: {
    // public: { http: ['https://allrpc.vrcscan.com/', "https://rpc-mainnet-7.vrcscan.com/", "https://rpc-mainnet-8.vrcscan.com/"] },
    public: {
      http: [
        "https://allrpc.vrcscan.com/",
        "https://rpc-mainnet-7.vrcscan.com/",
        "https://rpc-mainnet-8.vrcscan.com/",
      ],
    },
    default: { http: ["https://allrpc.vrcscan.com/"] },
    // default: { http: ["https://rpc-mainnet-5.vrcscan.com/"] },

  },
  blockExplorers: {
    etherscan: { name: "VRCscan", url: "https://vrcscan.com" },
    default: { name: "VRCscan", url: "https://vrcscan.com" },
  },
};
// const dxbchain = {`
//   id: 195,
//   name: 'DXB',
//   network: 'DXB chain',
//   iconUrl: 'https://cdn.discordapp.com/attachments/1097457892191371324/1136626528113926174/logo_B.gif',
//   iconBackground: '#fff',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'DXB chain',
//     symbol: 'DXB',
//   },
//   rpcUrls: {
//     public: { http: ['https://rpc-testnet-1.vrcscan.com/'] },
//     default: { http: ['https://rpc-testnet-1.vrcscan.com/'] },
//   },
//   blockExplorers: {
//     default: { name: 'VRCscan', url: 'https://dxb.vrcscan.com' },
//     etherscan: { name: 'VRCscan', url: 'https://dxb.vrcscan.com' },
//   },
//   testnet: true,
// };

const projectId = "9a487d505dfa263f3a1eb3f6ad7972de";

const chains = [vrcchain];
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  appName: "Web3Modal",
});

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <WagmiConfig config={wagmiConfig}>
      <App />
    </WagmiConfig>
  </BrowserRouter>
);

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// import { BrowserRouter } from 'react-router-dom';
// import '@rainbow-me/rainbowkit/styles.css';
// import { trustWallet, metaMaskWallet } from '@rainbow-me/rainbowkit/wallets';
// import { RainbowKitProvider, connectorsForWallets } from '@rainbow-me/rainbowkit';
// import { configureChains, createConfig, WagmiConfig } from 'wagmi';
// import { mainnet, polygon, optimism, arbitrum, goerli, polygonMumbai, sepolia, bsc } from 'wagmi/chains';
// import { publicProvider } from 'wagmi/providers/public';

// const vrcchain = {
//   id: 71,
//   name: 'VRC',
//   network: 'VRC chain',
//   iconUrl: 'https://cdn.discordapp.com/attachments/1097457892191371324/1136626528113926174/logo_B.gif',
//   iconBackground: '#fff',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'VRC chain',
//     symbol: 'VRC',
//   },
//   rpcUrls: {
//     public: { http: ['https://rpc-mainnet-2.vrcscan.com/', "https://rpc-mainnet-3.vrcscan.com/", "https://rpc-mainnet-4.vrcscan.com/"] },
//     default: { http: ['https://rpc-mainnet-2.vrcscan.com/'] },
//   },
//   blockExplorers: {
//     default: { name: 'VRCscan', url: 'https://vrcscan.com' },
//     etherscan: { name: 'VRCscan', url: 'https://vrcscan.com' },
//   },
//   testnet: false,
// };
// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [
//     vrcchain
//   ],
//   [
//     publicProvider()]
// );

// const projectId = "9a487d505dfa263f3a1eb3f6ad7972de"
// const connectors = connectorsForWallets([
//   {
//     groupName: 'Recommended',
//     wallets: [
//       // rainbowWallet({ projectId, chains }),
//       metaMaskWallet({ projectId, chains }),
//       trustWallet({ projectId, chains }),
//     ],
//   },
// ]);

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient,
//   webSocketPublicClient,
// });
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <BrowserRouter>
//     <WagmiConfig config={wagmiConfig}>
//       <RainbowKitProvider chains={chains}>
//         <App />
//       </RainbowKitProvider>
//     </WagmiConfig>
//   </BrowserRouter>
// );
