import React from "react";
import vo1 from "../../assets/new_ui/Coin.svg";
const Banner = () => {
  return (
    <div className="banner_bg">
      <div className="container mb-lg-5 mt-0">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 responsive d-block">
            <h1 className="change home-head mt-5">
              Earn free VRC <br className="d-lg-block d-none"></br> with VRC
              Network
            </h1>
            <p className="cole">
              Stake VRC and Build Wealth Forever.<br></br>
              VRC network is a decentralized blockchain based POS(Proof of
              Stake) platform. It utilizes state-of-the-art blockchain
              technology to power its decentralized ecosystem.
            </p>
            <div className="d-lg-flex d-block">
              <a href="https://play.google.com/store/apps/details?id=com.virtual.tech.v20_network" target="blank">
              <div className="outer center_mobile">
                <div className="button download_app">
                  <span className="text">Download the App</span>
                </div>
              </div>
              </a>
             
              <div className="outer mb-4">
                <div className="btn_main responsive">
                  <a
                    className="text-dark "
                    href="https://vrcscan.com/"
                    target="blank"
                  >
                    <div className="text">Connect With VRCSCAN</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="responsive me-lg-0 me-md-0 me-5">
              <img
                src={vo1}
                alt=""
                className="vol1IMG mt-lg-5 d-flex justify-content-center mx-auto movein"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
