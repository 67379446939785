import React from "react";
import download2 from "../../assets/new_ui/Secure And Transparent 3 1.svg";
const Secure = () => {
  return (
    <div className="container pt-lg-5 desk">
      <div className="row mb-lg-5 mt-lg-5 align-items-center">
        <div
          className="col-lg-6 col-md-12 card_start mb-4"
          style={{ position: "relative" }}
        >
          <div className="responsive">
            <img
              src={download2}
              alt=""
              className="d-flex justify-content-center mx-auto image_secure leftright"
              width={600}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 ">
          <h1 className="change start-head mb-5">Secure And Transparent</h1>
          <div className="res_secure">
            {/* <img className='line' src={download} alt="" width={200} /> */}
          </div>
          <p className="cole start_p">
            VRC network is fully decentralized, meaning no single entity has
            control over the network, ensuring security and trust audited by the
            world's largest audit company Hacken.<br></br>
            <br />
            It prioritizes security and transparency, ensuring the safety of
            user assets and network operations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Secure;
