import React, { useEffect, useState } from "react";
import { useAccount, useChainId } from "wagmi";
import { defaultChain } from "../../../config";
import { readContract, writeContract } from "../../../instances/instance";
import Skeleton from "react-loading-skeleton";
import { formatEther, parseEther } from "viem";

function TotalLevelStaked({ index, refresh, totalStaked }) {
  const { address, isConnected } = useAccount();
  const chain = useChainId();
  // const [totalStaked, setTotalStaked] = useState(null);
  // const getTotalStaked = async () => {
  //     try {
  //         const contractInstance =await writeContract();
  //         let userLevelDeposit = await contractInstance.userLevelDeposit(address, index);
  //         setTotalStaked(formatEther((userLevelDeposit)))
  //     } catch (error) {
  //         console.error("error while get total staked", error);
  //     }
  // }
  // useEffect(() => {
  //     if (isConnected  && chain === defaultChain)
  //         getTotalStaked()
  // }, [isConnected && chain, address, refresh])
  return (
    <>
      {/* {
                totalStaked === null ? <Skeleton
                    count={1}
                    inline
                    width={50}
                /> : Number(totalStaked).toFixed(2)
            } */}
    {totalStaked !== null ? (
        Number(totalStaked).toFixed(2)
      ) : (
        <Skeleton width={50} />
      )}
    </>
  );
}

export default TotalLevelStaked;
