import React from "react";
// import arow from "../../assets/right-arrow2.png";
import LevelSlider from "./Slider";

const Bonus = () => {
  return (
    <section className="commission-section">
      <div className="container">
        {/* <span className="change d-flex justify-content-center mt-5 ">REFERRAL BONUS LEVEL</span> */}
        <h1 className="change start-head fw-bold mt-3 d-flex justify-content-center text-center">
          Team Reward Percentage
        </h1>
        <LevelSlider />
      </div>
    </section>
  );
};

export default Bonus;
