import React, { useEffect, useState } from 'react'
import { useAccount, useChainId } from 'wagmi';
import { defaultChain } from '../../../config';
import { APIinstance } from '../../../axios.config';
import Skeleton from 'react-loading-skeleton';

function ActiveInactiveMembers({ level, activeMember, inActiveMember }) {
    const { address, isConnected } = useAccount()
    const chain = useChainId();
    // const [memberDetail, setMemberDetail] = useState({
    //     activeMember: null,
    //     inActiveMember: null
    // })
    // const getActiveInactiveMemberList = async () => {
    //     try {
    //         setMemberDetail({
    //             activeMember: null,
    //             inActiveMember: null
    //         })
    //         const { data: { activeMember, inActiveMember } } = await APIinstance(`/withdraw/getActiveInactiveMembes/${address}/${level}`);
    //         // console.log("dataofactive", data);
    //         setMemberDetail({ activeMember, inActiveMember })
    //     } catch (error) {
    //         console.error("error while get active and inactive member", error);
    //     }
    // }
    // useEffect(() => {
    //     if (isConnected && chain === defaultChain)
    //         getActiveInactiveMemberList()
    // }, [address, isConnected])
    return (
        <>
            <div className="col-lg-3 col-md-6 col-sm-12 ">
                <a
                    className="cart m-0 abut_cart  step-res mb-3 mb-lg-3 mb-md-3 "
                    href="#"
                >
                    <div className="p-2">
                        <h2 className=" text-value">
                            {
                                activeMember !== null ?
                                activeMember :
                                    <Skeleton
                                        count={1}
                                        inline
                                        width={50}
                                    />
                            }
                        </h2>
                        <h2 className=" text-center Vstack-title change">
                            Active Members
                        </h2>
                    </div>
                </a>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 ">
                <a
                    className="cart m-0 abut_cart  step-res mb-3 mb-lg-3 mb-md-3 "
                    href="#"
                >
                    <div className="p-2">
                        <h2 className=" text-value">
                            {
                                inActiveMember !== null ?
                                 inActiveMember :
                                    <Skeleton
                                        count={1}
                                        inline
                                        width={50}
                                    />
                            }
                        </h2>
                        <h2 className=" text-center Vstack-title change">
                            Inactive Members
                        </h2>
                    </div>
                </a>
            </div>
        </>
    )
}

export default ActiveInactiveMembers
