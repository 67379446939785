import React, { useEffect, useState } from 'react'
import { useAccount, useChainId } from 'wagmi'
import { readContract } from '../../../instances/instance';
import { defaultChain } from '../../../config';
import CountUp from 'react-countup';
import { formatEther } from 'viem';

function WestingReward({refresh,userDetailss}) {
  // console.log("userDetailss.westingReward",userDetailss.westingReward);
    const { address, isConnected } = useAccount();
    const chain = useChainId();
    const [westingReward, setWestingReward] = useState(0);
    // const getWestingReward = async () => {
    //     try {
    //         const contractInstance = readContract();
    //         let userTotalCherityWithdraw = await contractInstance.userTotalCherityWithdraw(address);
    //         setWestingReward(formatEther(userTotalCherityWithdraw))
    //     } catch (error) {
    //         console.error("error while get total users", error);
    //     }

    // }
    // useEffect(() => {
    //     if (isConnected && chain === defaultChain)
    //     getWestingReward()
    // }, [isConnected && chain, refresh, address])
  return (
    <>
      <CountUp className="text-center fs-2 fw-bold d-flex justify-content-center change" delay={2} end={userDetailss.westingReward} decimals={4} />
    </>
  )
}

export default WestingReward
