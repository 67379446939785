import React, { useEffect, useState } from 'react'
import { useAccount, useChainId } from 'wagmi'
import { readContract } from '../../../instances/instance';
import { defaultChain } from '../../../config';
import CountUp from 'react-countup';
import { formatEther } from 'viem';

function TotalWithdraw({refresh,userDetailss}) {
    const { address, isConnected } = useAccount();
    const chain = useChainId();
    const [totalReward, setTotalReward] = useState(0);
    // const getLevelReward = async () => {
    //     try {
    //         const contractInstance = readContract();
    //         let userTotalRewardWithdraw = await contractInstance.userTotalRewardWithdraw(address);
    //         setTotalReward(formatEther(userTotalRewardWithdraw))
    //     } catch (error) {
    //         console.error("error while get total users", error);
    //     }

    // }
    // useEffect(() => {
    //     if (isConnected && chain === defaultChain)
    //     getLevelReward()
    // }, [isConnected && chain, refresh, address])
  return (
    <>
      <CountUp className="text-center fs-2 fw-bold d-flex justify-content-center change" delay={2} end={userDetailss.totalReward} decimals={4} />
    </>
  )
}

export default TotalWithdraw
