// import React, { useEffect, useState } from 'react'
// import { useAccount } from 'wagmi'
// import { readContract } from '../../../instances/instance';
// import { formatEther, parseEther } from 'viem';
// import Skeleton from 'react-loading-skeleton';
// import UserStakingReward from './UserStakingReward';
// import { APIinstance } from '../../../axios.config';
// import { Pagination, Form } from 'react-bootstrap';

// function UserStakingAmount({ index, stakeLength, refresh}) {
//   const { address } = useAccount();
//   const [stakingDetail, setStakingDetail] = useState({
//     amount: null,
//     blockNO: null,
//     status: null,
//     time: null
//   });
//   useEffect(()=>{
//     getStakingApi();
//   },[])
//   const getStakingApi = async () =>{
//     try {
//       const data = await APIinstance.get('deposit/depositHistory?userAddress=0xef588e6048a95faee9b4376aed6564fe59f62675&page=1&limit=10');
//       console.log("data",data.data.deposits);
//       setStakingDetail(data?.data?.deposits);
//     } catch (error) {
//       console.error("error while get staking amount", error);
//     }
//   }
//   // const getStakingAmount = async () => {
//   //   try {
//   //     const readContractInstance = readContract();
//   //     let { amount, chainBlockN0, isUnStake, time } = await readContractInstance.stakeInfos(address, (stakeLength - 1) - index);
//   //     let dateObject = new Date(Number(time) * 1000)
//   //     setStakingDetail({
//   //       amount: formatEther((amount).toString()),
//   //       blockNO: Number(chainBlockN0),
//   //       status: isUnStake === false ? "Active" : "InActive" ,
//   //       time: dateObject.toISOString().slice(0, 19).replace('T', ' ')
//   //     })
//   //   } catch (error) {
//   //     console.error("error while get staking amount", error);
//   //   }
//   // }
//   // useEffect(() => {
//   //   getStakingAmount()
//   // }, [stakeLength])
//   return (
//     <>
//       <tr key={index}>
//         <th>{index + 1}</th>
//         <th>{stakingDetail.amount ? Number(stakingDetail.amount).toFixed(3) :
//           <Skeleton
//             count={1}
//             inline
//             width={100}
//           />}</th>
//         <th>{stakingDetail.blockNO !== null ? stakingDetail.blockNO :
//           <Skeleton
//             count={1}
//             inline
//             width={100}
//           />}</th>
//         <th>{stakingDetail.time ? stakingDetail.time :
//           <Skeleton
//             count={1}
//             inline
//             width={100}
//           />
//         }</th>
//         {/* <th><UserStakingReward refresh={refresh} index={(stakeLength - 1) - index} /></th> */}
//         <th className=''> {stakingDetail.status !== null ? stakingDetail.status  :
//           <Skeleton
//             count={1}
//             inline
//             width={100}
//           />}</th>
//       </tr>
//     </>
//   )
// }

// export default UserStakingAmount

import React, { useEffect, useState, useCallback } from "react";
import { useAccount } from "wagmi";
import { formatEther } from "viem";
import Skeleton from "react-loading-skeleton";
import { APIinstance } from "../../../axios.config";
import { Pagination, Form, Table } from "react-bootstrap";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowUnsorted } from "react-icons/ti";

function UserStakingAmount({ index, refresh }) {
  const { address } = useAccount();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 0,
    siblingCount: 1,
  });

  const [stakingDetails, setStakingDetails] = useState([]);
  // const [filter, setFilter] = useState("All");
  const [orderDircetion, setOrderDircetion] = useState("asc");

  const getStakingApi = useCallback(
    async (page, limit) => {
      try {
        // asc desc
        const response = await APIinstance.get(
          `deposit/depositHistory?userAddress=${address}&page=${page}&limit=${limit}&direction=${orderDircetion}`
        );
        const data = response.data;
        const totalPages = Math.ceil(data.params.total / limit);
        setStakingDetails(
          data.deposits.map((deposit) => ({
            index: deposit.index,
            amount: formatEther(deposit.amount),
            blockNO: deposit.blockNum,
            status: !deposit.isUnStake ? "Active" : "Inactive",
            time: new Date(Number(deposit.time) * 1000)
              .toISOString()
              .slice(0, 19)
              .replace("T", " "),
          }))
        );
        setPagination((prev) => ({ ...prev, totalPages: totalPages }));
      } catch (error) {
        console.error("error while getting staking amount", error);
      }
    },
    [address, orderDircetion]
  );

  useEffect(() => {
    getStakingApi(pagination.page, pagination.limit);
  }, [pagination.page, pagination.limit, getStakingApi]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleLimitChange = (e) => {
    const newLimit = Number(e.target.value);
    setPagination((prev) => ({ ...prev, page: 1, limit: newLimit }));
  };

  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  let startPage = Math.max(1, pagination.page - pagination.siblingCount);
  let endPage = Math.min(
    pagination.totalPages,
    pagination.page + pagination.siblingCount
  );
  let items = range(startPage, endPage).map((page) => (
    <Pagination.Item
      key={page}
      active={page === pagination.page}
      onClick={() => handlePageChange(page)}
    >
      {page}
    </Pagination.Item>
  ));

  return (
    <>
      <div className="table-responsive">
        <Table variant="dark" responsive>
          <thead>
            <tr className="table_head">
              <th>
                #
                {orderDircetion === "desc" ? (
                  <TiArrowUnsorted
                    className="fs-5"
                    onClick={() => setOrderDircetion("asc")}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <TiArrowUnsorted
                    className="fs-5"
                    onClick={() => setOrderDircetion("desc")}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </th>
              <th>Amount</th>
              <th>Block</th>
              <th>Date</th>
              {/* <th>Reward</th> */}
              <th>Status</th>
            </tr>
          </thead>
          {/* <tbody className="table_height">
            {stakingDetails.map((detail, index) => (
              <tr key={index}>
                <th>{Number(detail.index) + 1}</th>
                <th>
                  {detail.amount ? (
                    Number(detail.amount).toFixed(3)
                  ) : (
                    <Skeleton count={1} inline width={100} />
                  )}
                </th>
                <th>
                  {detail.blockNO !== null ? (
                    detail.blockNO
                  ) : (
                    <Skeleton count={1} inline width={100} />
                  )}
                </th>
                <th>
                  {detail.time ? (
                    detail.time
                  ) : (
                    <Skeleton count={1} inline width={100} />
                  )}
                </th>
                <th className="">
                  {detail.status !== null ? (
                    detail.status
                  ) : (
                    <Skeleton count={1} inline width={100} />
                  )}
                </th>
              </tr>
            ))}
          </tbody> */}
          <tbody className="table_height">
            {stakingDetails.length === 0 ? (
              <tr>
                <td colSpan="5" className="text-center p-3">
                  No records found
                </td>
              </tr>
            ) : (
              stakingDetails.map((detail, index) => (
                <tr key={index}>
                  <th>{Number(detail.index) + 1}</th>
                  <th>
                    {detail.amount ? (
                      Number(detail.amount).toFixed(3)
                    ) : (
                      <Skeleton count={1} inline width={100} />
                    )}
                  </th>
                  <th>
                    {detail.blockNO !== null ? (
                      detail.blockNO
                    ) : (
                      <Skeleton count={1} inline width={100} />
                    )}
                  </th>
                  <th>
                    {detail.time ? (
                      detail.time
                    ) : (
                      <Skeleton count={1} inline width={100} />
                    )}
                  </th>
                  <th className="">
                    {detail.status !== null ? (
                      detail.status
                    ) : (
                      <Skeleton count={1} inline width={100} />
                    )}
                  </th>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <div className="row align-items-center justify-content-center ">
        <div className="col-lg-6 col-6">
          <Form>
            <Form.Group
              controlId="limitSelect"
              className="d-lg-flex align-items-center gap-3 custom-select-wrapper"
            >
              <Form.Label className="change mb-0">Rows per page:</Form.Label>
              <Form.Control
                as="select"
                value={pagination.limit}
                onChange={handleLimitChange}
                className="custom-select-input"
                style={{ width: "70px" }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </div>
        <div className="col-lg-6 col-6 mt-4 pt-1 justify-content-end text-end">
          <Pagination size="md" className="justify-content-end text-end">
            <Pagination.First
              disabled={pagination.page === 1}
              onClick={() => handlePageChange(1)}
            />
            <Pagination.Prev
              disabled={pagination.page === 1}
              onClick={() => handlePageChange(pagination.page - 1)}
            />
            {items}
            <Pagination.Next
              disabled={pagination.page === pagination.totalPages}
              onClick={() => handlePageChange(pagination.page + 1)}
            />
            <Pagination.Last
              disabled={pagination.page === pagination.totalPages}
              onClick={() => handlePageChange(pagination.totalPages)}
            />
          </Pagination>
        </div>
      </div>
    </>
  );
}

export default UserStakingAmount;
