import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Routes,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";
import Navbar from "./components/NavBar/Navigat";
import Staki from "./pages/staking/Staking";
import Footer from "./components/footer/Footer";
import Hom from "./components/home/Home";
import Lanch from "./pages/lanchPad/LanchPad";
import Vesting from "./pages/vesting/Vesting";
import Leve from "./pages/level/Level";
import Referrl from "./pages/referral/Referral";
import Chat from "./Chat/Chat";
import "./App.css";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import LevelDetail from "./pages/level/LevelDetails";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Toaster } from "react-hot-toast";
import Loading from "./components/Loading/Loading";
import Privacy from "./components/privacy/Privacy";
import Eligibility from "./components/promotion/Eligibility";
import TwitterLogin from "./components/giveaway/TwitterLogin";
import Quiz from "./components/giveaway/Quiz";
import UploadImages from "./components/giveaway/UploadImages";
import NavMain from "./components/NavMain";
import LevelStakingDetails from "./pages/level/LevelStakingDetails";
import CheckMethod from "./components/CheckMethod";
import { useAccount } from "wagmi";
import ChatModal from "./Chat/ChatMobile";
const Home = lazy(() => import("./components/home/Home"));
const Promotion = lazy(() => import("./components/promotion/giveawayPromotion/Promotion"));
const Referral = lazy(() => import("./pages/referral/Referral"));
const Staking = lazy(() => import("./pages/staking/Staking"));
const Level = lazy(() => import("./pages/level/Level"));
const LanchPad = lazy(() => import("./pages/lanchPad/LanchPad"));
const LevelDetails = lazy(() => import("./pages/level/LevelDetails"));

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Suspense fallback={<Loading />}>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <Toaster position="bottom-center" reverseOrder={false} />
        {/* <NavMain /> */}
        <Navbar />
        {isMobile ? <ChatModal /> : <Chat />}{" "}
        <Routes>
          {/* <Route path="/" element={<CheckMethod />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/levels" element={<Level />} />
          <Route path="/withdraw" element={<LanchPad />} />
          <Route path="/levelDetails/:level" element={<LevelDetails />} />
          <Route
            path="/levelDetails/:level/:userAddress"
            element={<LevelStakingDetails />}
          />
          <Route path="/privacy-policy" element={<Privacy />} />
          {/* <Route path="/chat" element={<Chat />} /> */}

          <Route path="/promotion" element={<Promotion />} />
          <Route
            path="/staking-details/:userAddress"
            element={<LevelStakingDetails />}
          />

          <Route path="/eligibility/:isWinner" element={<Eligibility />} />
          {/* <Route path="/giveaway" element={<TwitterLogin />} /> */}
          {/* <Route path="/quiz/:id" element={<Quiz />} /> */}
          {/* <Route path="/upload-images/:id" element={<UploadImages />} />
          <Route path="/quiz" element={<Quiz />} /> */}

          {/* <Route path="/about" element={<About />} />
        <Route path="/vesting" element={<Vesting />} />
        <Route path="/contact" element={<Contact />} /> */}
        </Routes>
        <Footer />
      </SkeletonTheme>
    </Suspense>
  );
}

export default App;
