import React, { useEffect } from "react";
import level from "../../assets/new_ui/Level Details 1.svg";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Slider2 from "./Slider2";
import "./Level.css";
import Comming from "../../components/comming/Comming";

const Level = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="staking">
      <div className="">
        <div className="">
          <div className="container p-lg-5">
            <div className="row mb-lg-5 align-items-center">
              {/* <Comming /> */}

              <div className="col-lg-6 col-sm-12 mt-lg-0 mt-5 p-lg-5">
                <h1 className=" fs-1 fw-bold text-lg-start text-center mb-lg-3 level-sd change">
                  Level Details
                </h1>

                <p className="text-light text-lg-start text-center mt-lg-3 level-sd">
                  {" "}
                  {/* User can get upto 15 level referral reward <br className="d-lg-block d-none"/> percentage. Each
                  account will get 3x reward in  <br className="d-lg-block d-none"/> total */}
                  Don't miss out on the opportunity to maximize your earning potential with our multi-level referral program! Join today and start earning unlimited rewards by sharing our platform with others. With up to 15 levels of referral rewards available and triple rewards for each account, there's never been a better time to join our community and start earning passive income. Sign up now and unlock the power of unlimited referral rewards!
                </p>
              </div>
              <div className="col-lg-6 col-sm-12">
                <img className="vol1IMG leftright" src={level} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Slider2 />
    </section>
  );
};

export default Level;
