import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIinstance } from "../../axios.config";
import { formatEther } from "ethers";
import { Pagination, Form, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useAccount, useChainId } from "wagmi";
import { defaultChain } from "../../config";
import { FaArrowLeft } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";
import { TiArrowUnsorted } from "react-icons/ti";


const LevelStakingDetails = () => {
  const { level, userAddress } = useParams();
  const [stakingDetails, setStakingDetails] = useState([]);
  const { address, isConnected } = useAccount();
  const chain = useChainId();
  const navigate = useNavigate();
  const [orderDircetion, setOrderDircetion] = useState("asc")


  // const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 0,
    siblingCount: 1,
  });
  const fetchStakingDetails = useCallback(
    async (page, limit) => {
      // Check if connected to the right chain
      if (!isConnected || chain !== defaultChain) {
        setStakingDetails([]);
        return;
      }
      try {
        const response = await APIinstance.get(
          `deposit/depositHistory?userAddress=${userAddress}&page=${page}&limit=${limit}&direction=${orderDircetion}`
        );
        const data = response.data;
        const totalPages = Math.ceil(data.params.total / limit);
        setStakingDetails(data.deposits);
        setPagination((prev) => ({ ...prev, totalPages: totalPages }));
      } catch (error) {
        console.error("Failed to fetch staking details", error);
      }
    },
    [userAddress, isConnected, chain, orderDircetion]
  );

  useEffect(() => {
    fetchStakingDetails(pagination.page, pagination.limit);
  }, [pagination.page, pagination.limit, fetchStakingDetails]);
  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  let startPage = Math.max(1, pagination.page - pagination.siblingCount);
  let endPage = Math.min(
    pagination.totalPages,
    pagination.page + pagination.siblingCount
  );
  let items = range(startPage, endPage).map((page) => (
    <Pagination.Item
      key={page}
      active={page === pagination.page}
      onClick={() => handlePageChange(page)}
    >
      {page}
    </Pagination.Item>
  ));

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleLimitChange = (e) => {
    const newLimit = Number(e.target.value);
    setPagination((prev) => ({ ...prev, page: 1, limit: newLimit }));
  };

  // if (loading) return <p>Loading...</p>;

  return (
    <div className="container mt-5">
      <div className="back-button">
        <div className=" mt-5 px-3" onClick={() => navigate(-1)}>
          <div className="">
            <div className="backLevel">
              <BsArrowLeft />
            </div>
          </div>
        </div>
      </div>
      <h1 className="title change text-center">Level Details</h1>
      <p className="text-center text-white mb-2">{userAddress}</p>
      <div className="table-responsive">
        <Table variant="dark" responsive>
          <thead>
            <tr className="table_head">
              <th>#
                {
                  orderDircetion === "desc" ?
                    <TiArrowUnsorted className="fs-5" onClick={() => setOrderDircetion("asc")} style={{ cursor: 'pointer' }} />
                    :
                    <TiArrowUnsorted className="fs-5" onClick={() => setOrderDircetion("desc")} style={{ cursor: 'pointer' }} />
                }

              </th>
              <th>Stake Amount</th>
              <th>Block No #</th>
              <th>Status</th>
              {/* <th>Reward</th> */}
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {stakingDetails.length === 0
              ? [...Array(pagination.limit)].map((_, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  {/* <td><Skeleton count={1}/></td> */}
                  <td>
                    <Skeleton count={1} />
                  </td>
                </tr>
              ))
              : stakingDetails.map((detail, index) => (
                <tr key={index}>
                  <td>
                    {Number(detail.index) + 1}
                    {/* {(pagination.page - 1) * pagination.limit + index + 1} */}
                  </td>
                  <td>{Number(formatEther(detail.amount)).toFixed(4)}</td>
                  <td>{detail.blockNum}</td>
                  <td>{detail.isUnStake ? "Inactive" : "Active"}</td>
                  {/* <td>{detail.withdrawReward}</td> */}
                  <td>
                    {new Date(Number(detail.time) * 1000).toLocaleString()}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="row align-items-center justify-content-center ">
        <div className="col-lg-6 col-6">
          <Form>
            <Form.Group
              controlId="limitSelect"
              className="d-lg-flex align-items-center gap-3 custom-select-wrapper"
            >
              <Form.Label className="change mb-0">Rows per page:</Form.Label>
              <Form.Control
                as="select"
                value={pagination.limit}
                onChange={handleLimitChange}
                className="custom-select-input"
                style={{ width: "70px" }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </div>
        <div className="col-lg-6 justify-content-end text-end col-6 mt-4 pt-1">
          <Pagination size="md" className="justify-content-end text-end">
            <Pagination.First
              disabled={pagination.page === 1}
              onClick={() => handlePageChange(1)}
            />
            <Pagination.Prev
              disabled={pagination.page === 1}
              onClick={() => handlePageChange(pagination.page - 1)}
            />
            {items}
            <Pagination.Next
              disabled={pagination.page === pagination.totalPages}
              onClick={() => handlePageChange(pagination.page + 1)}
            />
            <Pagination.Last
              disabled={pagination.page === pagination.totalPages}
              onClick={() => handlePageChange(pagination.totalPages)}
            />
          </Pagination>
        </div>
      </div>
      {/* Pagination component here */}
    </div>
  );
};

export default LevelStakingDetails;
