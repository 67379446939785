import React, { useEffect, useState } from "react";
import stackBg from "../../assets/new_ui/Staking 1.svg";
import Skeleton from "react-loading-skeleton";
import { Form, Table } from "react-bootstrap";
import { AiOutlineNumber } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { MdStackedBarChart } from "react-icons/md";
import part from "../../assets/new_ui/Members 2 1.svg";
import parthover from "../../assets/new_ui/Members Black 1.svg";
import staked from "../../assets/new_ui/total Staking 1.svg";
import stakedhover from "../../assets/new_ui/total Staking  Black 1.svg";
import withdraw from "../../assets/new_ui/Withdrawals 2 1.svg";
import withdrawhover from "../../assets/new_ui/Withdrawals  Black 1.svg";
import profileimg from "../../assets/new_ui/Group 87.svg";
import { HiRefresh } from "react-icons/hi";
import toast from "react-hot-toast";
import { SiVirustotal } from "react-icons/si";
import coinTop from "../../assets/new_ui/coin 2 1.svg";
import "./staking.css";
import "./range.css";

import { useAccount, useChainId } from "wagmi";
import {
  getBlock,
  getBlockDetail,
  getWalletBalance,
  writeContract,
} from "../../instances/instance";
import { defaultChain, delay } from "../../config";
import { formatEther, zeroAddress, parseEther } from "viem";
import { useNavigate } from "react-router";
import BlockNumber from "../../components/childComponents/BlockNumber";
import UserStakingAmount from "./ChildComponents/UserStakingAmount";
import styled from "styled-components";
import { APIinstance } from "../../axios.config";
import DataLoading from "../../components/Loading/DataLoading";
import Comming from "../../components/comming/Comming";

const RotatingIcon = styled(HiRefresh)`
  transition: transform 1s ease-in-out; /* Adjusted time here */
  transform: rotate(${(props) => (props.rotated ? "360deg" : "0deg")});
  color: #b38728;
  cursor: pointer;
`;

const Card = ({ imgSrc, hoverImgSrc, title, detailText, noBorder }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardClass = `col-lg-4 col-md-6 col-sm-12 step-res ${
    noBorder ? "" : "border_right"
  }`;

  return (
    <div
      className={cardClass}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="cart p-4">
        <img
          src={isHovered ? hoverImgSrc : imgSrc}
          alt={title}
          className="d-flex justify-content-center mx-auto img-fluid"
        />
        <div className="cart-body text-center">
          {detailText !== null ? (
            <p className="text-center change_hover mt-2 fs-2 fw-bold">
              {detailText}
            </p>
          ) : (
            <Skeleton
              count={1}
              inline
              width={200}
              className="text-center d-flex justify-content-center mx-auto mb-3"
            />
          )}
          <h2 className="text-center fs-5 Vstack-title change">{title}</h2>
        </div>
      </div>
    </div>
  );
};
const Staking = () => {
  const [isRotated, setIsRotated] = useState(false);
  const handleRotate = () => {
    setIsRotated(!isRotated);
  };
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const chain = useChainId();
  const [userInfo, setUserInof] = useState({
    totalStaked: null,
    totalTeamStaked: null,
    userTotalWithdrawls: null,
    totalTeamNum: null,
    totalWithdraw: null,
  });
  const [refresh, setRefresh] = useState(false);
  const [stakeLength, setStakeLength] = useState(null);
  const [walletBal, setWalletBal] = useState(null);
  const [stakeValue, setStakeValue] = useState(1);
  const [loading, setLoading] = useState(false);
  const getBalance = async () => {
    try {
      const writeContractInstance = await writeContract();
      const staleLength = await writeContractInstance.stakeLength(address);
      setStakeLength(Number(staleLength));
      const userTotalRewardWithdraw =
        await writeContractInstance.userTotalRewardWithdraw(address);
      const { teamTotalDeposit, totalDeposit, teamNum } =
        await writeContractInstance.userInfo(address);
      setUserInof({
        totalStaked: formatEther(totalDeposit),
        totalTeamStaked: formatEther(teamTotalDeposit),
        totalTeamNum: Number(teamNum),
        totalWithdraw: formatEther(userTotalRewardWithdraw),
      });
      const bal = await getWalletBalance(address);
      setWalletBal(bal);
    } catch (error) {
      // console.error("error while get balance", error);
    }
  };
  useEffect(() => {
    if (isConnected && chain === defaultChain) getBalance();
  }, [isConnected, chain, address]);
  const getPercent = async (persentValue) => {
    if (walletBal) {
      if (persentValue === 1) {
        setStakeValue(persentValue);
      } else {
        setStakeValue((walletBal / 100) * persentValue);
      }
    } else {
      setStakeValue(0);
    }
  };

  const stakeAmount = async () => {
    try {
      const writeContractInstance = await writeContract();
      const { referrer } = await writeContractInstance.userInfo(address);

      if (referrer === zeroAddress) {
        toast.error(
          "You haven't registered yet please register your account first"
        );
        await delay(3000);
        navigate("/referral");
        return;
      }
      if (parseFloat(stakeValue) > parseFloat(walletBal)) {
        toast.error("Insufficient VRC coin in your account");
        return;
      }

      if (parseFloat(stakeValue) < 1) {
        toast.error("Minium stake value is 1 VRC");
        return;
      }
      setLoading(true);
      const {
        data: {
          amount,
          charityReward,
          stakeReward,
          levelReward,
          signature,
          nonce,
        },
      } = await APIinstance.post("/deposit/getDepositSignature", {
        amount: stakeValue,
        userAddress: address,
      });
      const trx = await writeContractInstance.deposit(
        parseEther(charityReward.toString()),
        parseEther(stakeReward.toString()),
        parseEther(levelReward.toString()),
        nonce,
        signature,
        {
          value: parseEther(stakeValue.toString()),
        }
      );
      let { hash } = await trx.wait();

      setLoading(false);
      getBalance();
    } catch (error) {
      setLoading(false);
      console.error("error while stake amount", error);
      if (error.response) {
        toast.error(error.response.data.message);
      }
    }
  };

  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = (e) => {
    const scrollTop = e.currentTarget.scrollTop;
    setIsScrolling(scrollTop > 0);
  };

  const [sliderValue, setSliderValue] = useState(0);

  // This function now checks for exact equality.
  const isActive = (value) => {
    return sliderValue === value;
  };

  const handleTickClick = (persentValue) => {
    // setSliderValue(value);
    setSliderValue(persentValue);

    if (walletBal) {
      if (persentValue === 1) {
        setStakeValue(persentValue);
      } else {
        setStakeValue((walletBal / 100) * persentValue);
      }
    } else {
      setStakeValue(0);
    }
  };

  return (
    <section className=" mt-lg-0 mt-md-5 mt-5">
      {loading && <DataLoading />}
      <div className="staking">
        <div className="">
          <div className="container">
            <div className="row mt-lg-5 align-items-center">
              {/* <Comming /> */}

              <div className="col-lg-6 col-sm-12 mt-lg-5 p-lg-5 responsive d-block">
                <span
                  className="text-light fs-1 fw-bold mb-lg-5 stak-sd change"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <br></br>
                  <span className="change">Stake VRC Coins & Earn Rewards</span>
                </span>

                <p className="text-light mt-lg-3 stak-sd">
                  In order to participate in the VRC network, a node{" "}
                  <br className="d-lg-block d-none" /> operator must stake a
                  minimum required amount <br className="d-lg-block d-none" />{" "}
                  of 1 VRC.
                </p>
              </div>

              <div className="col-lg-6 stak-sd col-sm-12">
                <img className="vol1IMG leftright" src={stackBg} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5 p-lg-5 p-4">
          <h1 className="change home-head text-center mt-5 pb-3">
            VRC Network Staking
          </h1>
          <div className="row mt-5 bg_cards before">
            <img src={coinTop} alt="" className="top_coin" />
            <Card
              imgSrc={part}
              hoverImgSrc={parthover}
              title="Total Participants"
              detailText={userInfo.totalTeamNum}
            />
            <Card
              imgSrc={staked}
              hoverImgSrc={stakedhover}
              title="Total Staked"
              detailText={
                userInfo.totalStaked && Number(userInfo.totalStaked).toFixed(4)
              }
            />
            <Card
              imgSrc={withdraw}
              hoverImgSrc={withdrawhover}
              title="Total Withdrawal"
              detailText={
                userInfo.totalWithdraw &&
                Number(userInfo.totalWithdraw).toFixed(4)
              }
              noBorder={true}
            />
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <div className="row mt-2">
          <div className="col-lg-6 col-md-12">
            <div
              className="w-100 p-3 mt-1 detail-card mb-3"
              // style={{
              //   backgroundColor: "#222222",
              //   borderRadius: "15px",
              //   height: "370px",
              // }}
            >
              <div className="nordek wd">
                <div className="content d-flex justify-content-between">
                  <span className="span change fw-bold">
                    {/* <AiOutlineNumber className="text-light" /> */}
                    Block Number :
                  </span>
                  <p className="cole mt-1">
                    <BlockNumber />
                  </p>
                </div>
                <hr className="cole w-100" />
              </div>
              <div className="nordek wd">
                <div className="content d-flex justify-content-between">
                  <span className="span change fw-bold">
                    {/* <MdOutlineAccountBalanceWallet className="text-light" />{" "} */}
                    Balance :
                  </span>
                  {walletBal ? (
                    <p className="cole mt-1">
                      {Number(walletBal).toFixed(4)} VRC
                    </p>
                  ) : (
                    <p className="cole mt-1">
                      <Skeleton count={1} inline width={200} />
                    </p>
                  )}
                </div>
                <hr className="cole w-100" />
              </div>
              <div className="nordek wd">
                <div className="content d-flex justify-content-between">
                  <span className="span change fw-bold">
                    {/* <MdStackedBarChart className="text-light" />  */}
                    Your Total Staked :
                  </span>
                  {userInfo.totalStaked ? (
                    <p className="cole mt-1">
                      {Number(userInfo.totalStaked).toFixed(4)} VRC
                    </p>
                  ) : (
                    <p className="cole mt-1">
                      <Skeleton count={1} inline width={200} />
                    </p>
                  )}
                </div>
                <hr className="cole w-100" />
              </div>
              <div className="nordek wd">
                <div className="content d-flex justify-content-between">
                  <span className="span change fw-bold">
                    {/* <SiVirustotal className="text-light" /> */}
                    Total Team Staked :
                  </span>
                  <p className="cole mt-1">
                    {userInfo.totalTeamStaked ? (
                      <p className="cole mt-1">
                        {Number(userInfo.totalTeamStaked).toFixed(4)} VRC
                      </p>
                    ) : (
                      <p className="cole mt-1">
                        <Skeleton count={1} inline width={200} />
                      </p>
                    )}
                  </p>
                </div>
                {/* <hr className='cole w-100' /> */}
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-12">
            <div className="p-lg-3 p-1 detail-card">
              <div className="profileData d-flex">
                <div className="circle">
                  <img src={profileimg} alt="Admin" />
                </div>
                <div className="data mt-3 ms-2">
                  <div className="cole fw-bold text-wrapper">{address}</div>
                  <div className="line2 mt-2 rounded"></div>
                </div>
              </div>
              <div className="nordek_right_first_input p-2 d-lg-flex d-block mt-1">
                <Form.Control
                  className="p-3 border_main"
                  type="number"
                  value={stakeValue}
                  onChange={(e) => setStakeValue(e.target.value)}
                  placeholder="0.00"
                />
                {/* <span className="symbol text-dark">VRC</span> */}
              </div>
              {/* <div className="row mt-lg-1 mt-0 responsive">
                <div className="col-3 btn" onClick={() => getPercent(1)}>
                  <div className=" text-center persent cole">Min</div>
                </div>
                <div className="col-3 btn" onClick={() => getPercent(25)}>
                  <div className=" text-center persent cole ">25 %</div>
                </div>
                <div className="col-3 btn" onClick={() => getPercent(50)}>
                  <div className=" text-center persent cole">50 %</div>
                </div>
                <div className="col-3 btn" onClick={() => getPercent(75)}>
                  <div className=" text-center persent cole">75 %</div>
                </div>
              </div> */}

              {/* new range */}

              <div className="slider-container mt-2 mb-2">
                <input
                  type="range"
                  min="0"
                  max="75"
                  value={sliderValue}
                  onChange={(e) => setSliderValue(Number(e.target.value))}
                  className="slider"
                  id="myRange"
                  style={{ "--thumb-percentage": `${sliderValue}%` }}
                />
                {/* Add onClick event handler to each tick mark */}
                {[0, 30, 65, 99].map((value) => (
                  <div
                    key={value}
                    className={`tick-mark ${
                      isActive(value) ? "active_doamond" : ""
                    }`}
                    style={{ left: `${value}%` }}
                    onClick={() => handleTickClick(value)}
                    title={`${value}%`} // Add the title attribute for the tooltip
                    data-bs-toggle="tooltip" // Enable Bootstrap tooltip
                    data-bs-placement="top" // Set tooltip placement
                  ></div>
                ))}
                <div className="slider-labels">
                  {/* Labels can also be clickable if you want */}
                  <div
                    className="label"
                    style={{ left: "0%" }}
                    onClick={() => handleTickClick(1)}
                  ></div>
                  <div
                    className="label"
                    style={{ left: "30%" }}
                    onClick={() => handleTickClick(25)}
                  ></div>
                  <div
                    className="label"
                    style={{ left: "65%" }}
                    onClick={() => handleTickClick(50)}
                  ></div>
                  <div
                    className="label"
                    style={{ left: "99%" }}
                    onClick={() => handleTickClick(75)}
                  ></div>
                  {/* <div
                    className="label"
                    style={{ left: "100%" }}
                    onClick={() => handleTickClick(100)}
                  ></div> */}
                </div>
              </div>

              <button
                className="outer btn mobile_btn mt-0 d-flex mx-auto justify-content-center align-items-center"
                onClick={stakeAmount}
              >
                <div className="btn_main responsive">
                  <div className="text text-dark">Stake VRC</div>
                </div>
              </button>
              {/* <div className="d-flex justify-content-center mt-3">

                <SwapModalA />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* {stakeLength && ( */}
        <div className="container pt-5 mb-5">
          {/* <div className="lower_bsc_table back  mt-2 mb-lg-0 mb-4 rounded p-2 detail-card d-flex justify-content-between"> */}
          {/* <div className="bsc_head"> */}
          <h1 className="change mt-3 text-center mb-3">Stake History</h1>
          {/* </div> */}
          {/* <div className="bsc_head">
              <button
                className="btn  mt-2 fs-3"
                onClick={() => setRefresh(!refresh)}
              >
                <span className="">
                  <RotatingIcon rotated={isRotated} onClick={handleRotate} />
                </span>
              </button>
            </div> */}

          <UserStakingAmount />
          {/* <div className="table-responsive" onScroll={handleScroll}>
            <Table variant="dark" responsive>
              <thead className={isScrolling ? "table-scrolling" : ""}>
                <tr className="table_head">
                  <th>#</th>
                  <th>Amount</th>
                  <th>Block</th>
                  <th>Date</th>
                  <th>Reward</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="table_height">
                  <UserStakingAmount
                    key={index}
                    index={index}
                    stakeLength={stakeLength}
                    refresh={refresh}
                  />
              </tbody>
            </Table>
          </div> */}
        </div>
      {/* )} */}
    </section>
  );
};

export default Staking;
