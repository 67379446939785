import React, { Component } from "react";
import Slider from "react-slick";
import arow from "../../assets/new_ui/arrow F 1.png";
import leftArrow from "../../assets/new_ui/left-arrow_318399 1.svg"; // Path to your left arrow image
import rightArrow from "../../assets/new_ui/left-arrow_318399 2.svg";




export default class LevelSlider extends Component {
  render() {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        initialSlide: 0,
      cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
        <div className="row justify-content-center align-items-center text-center mx-auto mt-5">
        <Slider {...settings}>
            {/* first */}
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">15%</h5>
                <h6>Level 01</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>

           {/* second */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">12%</h5>
                <h6>Level 02</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
             {/* third */}
             <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">10%</h5>
                <h6>Level 03</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
{/* forth */}

<div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">7%</h5>
                <h6>Level 04</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* fifth */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">5%</h5>
                <h6>Level 05</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* sixth */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">3%</h5>
                <h6>Level 06</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* seven */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">2%</h5>
                <h6>Level 07</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* eigth */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">2%</h5>
                <h6>Level 08</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* ninth */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">2%</h5>
                <h6>Level 09</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>

          {/* 10 */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">2%</h5>
                <h6>Level 10</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* 11 */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">2%</h5>
                <h6>Level 11</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* 12 */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">2%</h5>
                <h6>Level 12</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* 13 */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">3%</h5>
                <h6>Level 13</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* 14 */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-center text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">3%</h5>
                <h6>Level 14</h6>
              </div>
            </div>
          </div>
          <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div>
          </div>
          </div>
          {/* 15 */}
          <div>
          <div className="d-flex align-items-center">
          <div className="col d-flex justify-content-start text-center mb-lg-0 mb-md-0 mb-3">
            <div className="box_2 ">
              <div className="box d-block text-white">
                <h5 className="change fs-2 fw-bold">5%</h5>
                <h6>Level 15</h6>
              </div>
            </div>
          </div>
          {/* <div className="col d-lg-flex d-none ms-5">
            <img width={80} height={80} src={arow} alt="" />
          </div> */}
          </div>
          </div>
        </Slider>
      </div>
    );
  }
}