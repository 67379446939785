import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { APIinstance } from "../../axios.config";
import { useAccount } from "wagmi";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";

const Eligibility = (props) => {
  const { tierData } = props;
  const { address, isConnected } = useAccount();

  const [formData, setFormData] = useState({
    // profileImage: null,
    uplineName: "",
    uplineAddress: 0,
    achieverName: "",
    // achievedTier: 0,
    achievedTier: tierData?.achievedTier || 1,
    mobileNumber: 0,
    walletAddress: "",
    emailAddress: "",
    countryName: "",
    passportNumber: "",
    // passportFrontImage: null,
    // passportBackImage: null,
    // cnicFrontImage: null,
    // cnicBackImage: null,
  });

  useEffect(() => {
    if (isConnected && address) {
      setFormData(prevData => ({
        ...prevData,
        walletAddress: address,
      }));
    }
  }, [address, isConnected]);
  

  const [uploadedImages, setUploadedImages] = useState({
    profileImage: null,
    passportImage: null,
    passportFrontImage: null,
    passportBackImage: null,
    cnicFrontImage: null,
    cnicBackImage: null,
  });

  const handleImageUpload = async (id, base64Image) => {
    try {
      const response = await APIinstance.post("pormotion/upload", {
        imageBase64: base64Image,
      });
      // console.log("reposenimages", response.data);
      setUploadedImages((prev) => ({ ...prev, [id]: response.data }));
      // console.log("Uploaded images:", uploadedImages);
      setFormData((prev) => ({ ...prev, [id]: response.data }));
      toast.success("Image uploaded successfully!");
    } catch (error) {
      console.error("Image Upload Error:", error);
      toast.error("Failed to upload image.");
    }
  };
  const handleInputChange = (e) => {
    const { id, value, type, files } = e.target;

    if (type === "file") {
      if (files[0].size > 1 * 1024 * 1024) {
        toast.error("File size should not exceed 1MB");
        return;
      } else if (id !== "walletAddress" || !isConnected) {
        setFormData((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        const arrayBuffer = reader.result;
        const base64String = arrayBufferToBase64(arrayBuffer);

        await handleImageUpload(id, base64String);
      };
      reader.readAsArrayBuffer(files[0]);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  function arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting formData:", formData);

    const requiredFields = [
      "uplineName",
      "achieverName",
      // "achievedTier",
      "uplineAddress",
      "mobileNumber",
      "walletAddress",
      "emailAddress",
      "countryName",
      "passportNumber",
      "profileImage",
      "passportFrontImage",
      "passportBackImage",
      "cnicFrontImage",
      "passportImage",
      "cnicBackImage",
    ];

    const allFieldsFilled = requiredFields.every((field) => formData[field]);
    if (!allFieldsFilled) {
      toast.error("Please fill all the inputs and upload all images.");
      return;
    }
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.emailAddress)) {
      toast.error("Invalid email address format");
      return;
    }
    if (formData.walletAddress === formData.uplineAddress) {
      toast.error("Wallet address and upline address cannot be the same.");
      return;
    }
    const payload = {
      ...formData,
      walletAddress: formData.walletAddress.toLowerCase(),
      uplineAddress: formData.uplineAddress.toLowerCase(),
      profileImage: uploadedImages.profileImage,
      passportImage: uploadedImages.passportImage,
      passportFrontImage: uploadedImages.passportFrontImage,
      passportBackImage: uploadedImages.passportBackImage,
      cnicFrontImage: uploadedImages.cnicFrontImage,
      cnicBackImage: uploadedImages.cnicBackImage,
    };
    // console.log("Payload for submission:", payload);

    try {
      const response = await APIinstance.post("pormotion/winnerInfo", payload);
      // console.log("responseresponse",response);
      toast.success("Successfully Submitted");
      // console.log("Final API Response:", response.data);
      props.onFormSubmitted();
      setFormData({
        profileImage: "",
        uplineName: "",
        uplineAddress: "",
        achieverName: "",
        achievedTier: "",
        mobileNumber: "",
        walletAddress: "",
        emailAddress: "",
        countryName: "",
        passportNumber: "",
        passportFrontImage: "",
        passportBackImage: "",
        cnicFrontImage: "",
        cnicBackImage: "",
      });
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  return (
    <div className="container mt-3 mb-5">
      <h1 className="text-white text-center change home-head">
        Promotion Eligibility
      </h1>
      <p className="text-center">
        Only individuals who meet the eligibility criteria are authorized to
        complete this form.
      </p>

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label text-white"
                >
                  Profile image:
                </label>
                <input
                  type="file"
                  className="form-control w-100"
                  id="profileImage"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <label htmlFor="exampleInputEmail1" className="form-label text-white">
            Upline Name:
          </label>
          <input
            type="text"
            id="uplineName"
            value={formData.uplineName}
            onChange={handleInputChange}
            className="form-control text-white"
            aria-describedby="emailHelp"
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="exampleInputPassword1"
            className="form-label text-white"
          >
            Upline Wallet:
          </label>
          <input
            type="text"
            id="uplineAddress"
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="exampleInputPassword1"
            className="form-label text-white"
          >
            Achiever Name:
          </label>
          <input
            type="text"
            id="achieverName"
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label
            fohtmlFor="exampleInputPassword1"
            className="form-label text-white"
          >
            Mention Achieved Tier 1,2 or 3:
          </label>
          <input
            type="number"
            id="achievedTier"
            value={formData.achievedTier}
            onChange={handleInputChange}
            readOnly
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="exampleInputPassword1"
            className="form-label text-white"
          >
            Mobile Number:
          </label>
          <input
            type="number"
            id="mobileNumber"
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="walletAddress" className="form-label text-white">
            Wallet Address:
          </label>
          <input
             type="text"
             id="walletAddress"
             value={formData.walletAddress}
             onChange={handleInputChange}
             className="form-control"
             readOnly={!!address}
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="exampleInputPassword1"
            className="form-label text-white"
          >
            Email Address:
          </label>
          <input
            type="text"
            id="emailAddress"
            onChange={handleInputChange}
            className="form-control"
          />
          <div id="emailHelp" className="form-text text-white">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="exampleInputPassword1"
            className="form-label text-white"
          >
            Country Name:{" "}
          </label>
          <input
            type="text"
            id="countryName"
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="exampleInputPassword1"
            className="form-label text-white"
          >
            Passport number:{" "}
          </label>
          <input
            type="text"
            id="passportNumber"
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="mb-3">
              <label
                htmlFor="passportFrontImage"
                className="form-label text-white"
              >
                Passport image (Passport size with white background):
              </label>
              <input
                type="file"
                className="form-control"
                id="passportImage"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="mb-3">
              <label
                htmlFor="passportFrontImage"
                className="form-label text-white"
              >
                Passport Front Image:
              </label>
              <input
                type="file"
                className="form-control"
                id="passportFrontImage"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="passportBackImage" className="form-label text-white">
            Passport Back Image:
          </label>
          <input
            type="file"
            className="form-control"
            id="passportBackImage"
            onChange={handleInputChange}
          />
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="mb-3">
              <label htmlFor="cnicFrontImage" className="form-label text-white">
                CNIC Front Image:
              </label>
              <input
                type="file"
                className="form-control"
                id="cnicFrontImage"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="mb-3">
              <label htmlFor="cnicBackImage" className="form-label text-white">
                CNIC Back Image:
              </label>
              <input
                type="file"
                className="form-control"
                id="cnicBackImage"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="text-dark bg-transparent justify-content-lg-start w-100 d-flex  text-center border-0"
        >
          <div className="outer mb-4">
            <div className="outer mb-4">
              <div className="btn_main responsive mobile_screen text-center">
                <div className="text">Submit</div>
              </div>
            </div>
          </div>
        </button>
      </form>
    </div>
  );
};

export default Eligibility;




// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import toast from "react-hot-toast";
// import { APIinstance } from "../../axios.config";
// import { useAccount } from "wagmi";
// import { Navigate, useLocation, useNavigate, useParams } from "react-router";

// const Eligibility = (props) => {
//   const { tierData } = props;
//   const { address, isConnected } = useAccount();

//   const [formData, setFormData] = useState({
//     // profileImage: null,
//     uplineName: "",
//     uplineAddress: 0,
//     achieverName: "",
//     // achievedTier: 0,
//     achievedTier: tierData?.achievedTier || 0,
//     mobileNumber: 0,
//     walletAddress: "",
//     emailAddress: "",
//     countryName: "",
//     passportNumber: "",
//     // passportFrontImage: null,
//     // passportBackImage: null,
//     // cnicFrontImage: null,
//     // cnicBackImage: null,
//   });

//   // useEffect(() => {
//   //   if (isConnected && address) {
//   //     setFormData(prevData => ({
//   //       ...prevData,
//   //       walletAddress: address,
//   //     }));
//   //   }
//   // }, [address, isConnected]);
  

//   const [uploadedImages, setUploadedImages] = useState({
//     profileImage: null,
//     passportImage: null,
//     passportFrontImage: null,
//     passportBackImage: null,
//     cnicFrontImage: null,
//     cnicBackImage: null,
//   });

//   const handleImageUpload = async (id, base64Image) => {
//     try {
//       const response = await APIinstance.post("pormotion/upload", {
//         imageBase64: base64Image,
//       });
//       // console.log("reposenimages", response.data);
//       setUploadedImages((prev) => ({ ...prev, [id]: response.data }));
//       // console.log("Uploaded images:", uploadedImages);
//       setFormData((prev) => ({ ...prev, [id]: response.data }));
//       toast.success("Image uploaded successfully!");
//     } catch (error) {
//       console.error("Image Upload Error:", error);
//       toast.error("Failed to upload image.");
//     }
//   };
//   // const handleInputChange = (e) => {
//   //   const { id, value, type, files } = e.target;

//   //   if (type === "file") {
//   //     if (files[0].size > 1 * 1024 * 1024) {
//   //       toast.error("File size should not exceed 1MB");
//   //       return;
//   //     } else if (id !== "walletAddress" || !isConnected) {
//   //       setFormData((prevData) => ({
//   //         ...prevData,
//   //         [id]: value,
//   //       }));
//   //     }

//   //     const reader = new FileReader();
//   //     reader.onloadend = async () => {
//   //       const arrayBuffer = reader.result;
//   //       const base64String = arrayBufferToBase64(arrayBuffer);

//   //       await handleImageUpload(id, base64String);
//   //     };
//   //     reader.readAsArrayBuffer(files[0]);
//   //   } else {
//   //     setFormData((prevData) => ({
//   //       ...prevData,
//   //       [id]: value,
//   //     }));
//   //   }
//   // };


//   const handleInputChange = (e) => {
//     const { id, value, type, files } = e.target;

//     if (type === "file") {
//       if (files[0].size > 1 * 1024 * 1024) {
//         toast.error("File size should not exceed 1MB");
//         return;
//       }
//       const reader = new FileReader();
//       reader.onloadend = async () => {
//         const arrayBuffer = reader.result;
//         const base64String = arrayBufferToBase64(arrayBuffer);
//         await handleImageUpload(id, base64String);
//       };
//       reader.readAsArrayBuffer(files[0]);
//     } else {
//       setFormData((prevData) => ({
//         ...prevData,
//         [id]: value,
//       }));
//     }
//   };


//   function arrayBufferToBase64(buffer) {
//     let binary = "";
//     let bytes = new Uint8Array(buffer);
//     let len = bytes.byteLength;
//     for (let i = 0; i < len; i++) {
//       binary += String.fromCharCode(bytes[i]);
//     }
//     return window.btoa(binary);
//   }


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log("Submitting formData:", formData);

//     const requiredFields = [
//       "uplineName",
//       "achieverName",
//       "achievedTier",
//       "uplineAddress",
//       "mobileNumber",
//       "walletAddress",
//       "emailAddress",
//       "countryName",
//       "passportNumber",
//       "profileImage",
//       "passportFrontImage",
//       "passportBackImage",
//       "cnicFrontImage",
//       "passportImage",
//       "cnicBackImage",
//     ];

//     // const allFieldsFilled = requiredFields.every((field) => formData[field]);
//     // if (!allFieldsFilled) {
//     //   toast.error("Please fill all the inputs and upload all images.");
//     //   return;
//     // }
//     const formDataToSend = new FormData();
//     Object.entries(formData).forEach(([key, value]) => {
//       formDataToSend.append(key, value);
//     });

//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!emailRegex.test(formData.emailAddress)) {
//       toast.error("Invalid email address format");
//       return;
//     }
//     if (formData.walletAddress === formData.uplineAddress) {
//       toast.error("Wallet address and upline address cannot be the same.");
//       return;
//     }
//     const payload = {
//       ...formData,
//       walletAddress: formData.walletAddress.toLowerCase(),
//       uplineAddress: formData.uplineAddress.toLowerCase(),
//       profileImage: uploadedImages.profileImage,
//       passportImage: uploadedImages.passportImage,
//       passportFrontImage: uploadedImages.passportFrontImage,
//       passportBackImage: uploadedImages.passportBackImage,
//       cnicFrontImage: uploadedImages.cnicFrontImage,
//       cnicBackImage: uploadedImages.cnicBackImage,
//     };
//     // console.log("Payload for submission:", payload);

//     try {
//       const response = await APIinstance.post("pormotion/winnerInfo", payload);
//       toast.success("Successfully Submitted");
//       // console.log("Final API Response:", response.data);
//       props.onFormSubmitted();
//       setFormData({
//         profileImage: "",
//         uplineName: "",
//         uplineAddress: "",
//         achieverName: "",
//         achievedTier: "",
//         mobileNumber: "",
//         walletAddress: "",
//         emailAddress: "",
//         countryName: "",
//         passportNumber: "",
//         passportFrontImage: "",
//         passportBackImage: "",
//         cnicFrontImage: "",
//         cnicBackImage: "",
//       });
//     } catch (error) {
//       console.error("API Error:", error.message);
//     }
//   };

//   return (
//     <div className="container mt-3 mb-5">
//       <h1 className="text-white text-center change home-head">
//         Promotion Eligibility
//       </h1>
//       <p className="text-center">
//         Only individuals who meet the eligibility criteria are authorized to
//         complete this form.
//       </p>

//       <form onSubmit={handleSubmit}>
//         <div className="mb-3">
//           <div className="row">
//             <div className="col-lg-12">
//               <div className="mb-3">
//                 <label
//                   htmlFor="exampleInputPassword1"
//                   className="form-label text-white"
//                 >
//                   Profile image:
//                 </label>
//                 <input
//                   type="file"
//                   className="form-control w-100"
//                   id="profileImage"
//                   onChange={handleInputChange}
//                 />
//               </div>
//             </div>
//           </div>

//           <label htmlFor="exampleInputEmail1" className="form-label text-white">
//             Upline Name:
//           </label>
//           <input
//             type="text"
//             id="uplineName"
//             value={formData.uplineName}
//             onChange={handleInputChange}
//             className="form-control text-white"
//             aria-describedby="emailHelp"
//           />
//         </div>
//         <div className="mb-3">
//           <label
//             htmlFor="exampleInputPassword1"
//             className="form-label text-white"
//           >
//             Upline Wallet:
//           </label>
//           <input
//             type="text"
//             id="uplineAddress"
//             onChange={handleInputChange}
//             className="form-control"
//           />
//         </div>
//         <div className="mb-3">
//           <label
//             htmlFor="exampleInputPassword1"
//             className="form-label text-white"
//           >
//             Achiever Name:
//           </label>
//           <input
//             type="text"
//             id="achieverName"
//             onChange={handleInputChange}
//             className="form-control"
//           />
//         </div>
//         <div className="mb-3">
//           <label
//             fohtmlFor="exampleInputPassword1"
//             className="form-label text-white"
//           >
//             Mention Achieved Tier 1,2 or 3:
//           </label>
//           <input
//             type="number"
//             id="achievedTier"
//             value={formData.achievedTier}
//             onChange={handleInputChange}
//             readOnly
//             className="form-control"
//           />
//         </div>
//         <div className="mb-3">
//           <label
//             htmlFor="exampleInputPassword1"
//             className="form-label text-white"
//           >
//             Mobile Number:
//           </label>
//           <input
//             type="number"
//             id="mobileNumber"
//             onChange={handleInputChange}
//             className="form-control"
//           />
//         </div>
//         <div className="mb-3">
//           <label htmlFor="walletAddress" className="form-label text-white">
//             Wallet Address:
//           </label>
//           <input
//              type="text"
//              id="walletAddress"
//              value={formData.walletAddress}
//              onChange={handleInputChange}
//              className="form-control"
//             //  readOnly={!!address}
//           />
//         </div>
//         <div className="mb-3">
//           <label
//             htmlFor="exampleInputPassword1"
//             className="form-label text-white"
//           >
//             Email Address:
//           </label>
//           <input
//             type="text"
//             id="emailAddress"
//             onChange={handleInputChange}
//             className="form-control"
//           />
//           <div id="emailHelp" className="form-text text-white">
//             We'll never share your email with anyone else.
//           </div>
//         </div>
//         <div className="mb-3">
//           <label
//             htmlFor="exampleInputPassword1"
//             className="form-label text-white"
//           >
//             Country Name:{" "}
//           </label>
//           <input
//             type="text"
//             id="countryName"
//             onChange={handleInputChange}
//             className="form-control"
//           />
//         </div>
//         <div className="mb-3">
//           <label
//             htmlFor="exampleInputPassword1"
//             className="form-label text-white"
//           >
//             Passport number:{" "}
//           </label>
//           <input
//             type="text"
//             id="passportNumber"
//             onChange={handleInputChange}
//             className="form-control"
//           />
//         </div>
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="mb-3">
//               <label
//                 htmlFor="passportFrontImage"
//                 className="form-label text-white"
//               >
//                 Passport image (Passport size with white background):
//               </label>
//               <input
//                 type="file"
//                 className="form-control"
//                 id="passportImage"
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="mb-3">
//               <label
//                 htmlFor="passportFrontImage"
//                 className="form-label text-white"
//               >
//                 Passport Front Image:
//               </label>
//               <input
//                 type="file"
//                 className="form-control"
//                 id="passportFrontImage"
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="mb-3">
//           <label htmlFor="passportBackImage" className="form-label text-white">
//             Passport Back Image:
//           </label>
//           <input
//             type="file"
//             className="form-control"
//             id="passportBackImage"
//             onChange={handleInputChange}
//           />
//         </div>
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="mb-3">
//               <label htmlFor="cnicFrontImage" className="form-label text-white">
//                 CNIC Front Image:
//               </label>
//               <input
//                 type="file"
//                 className="form-control"
//                 id="cnicFrontImage"
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="mb-3">
//               <label htmlFor="cnicBackImage" className="form-label text-white">
//                 CNIC Back Image:
//               </label>
//               <input
//                 type="file"
//                 className="form-control"
//                 id="cnicBackImage"
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//         </div>
//         <button
//           type="submit"
//           className="text-dark bg-transparent justify-content-lg-start w-100 d-flex  text-center border-0"
//         >
//           <div className="outer mb-4">
//             <div className="outer mb-4">
//               <div className="btn_main responsive mobile_screen text-center">
//                 <div className="text">Submit</div>
//               </div>
//             </div>
//           </div>
//         </button>
//       </form>
//     </div>
//   );
// };

// export default Eligibility;

