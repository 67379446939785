import React from 'react'
import dragon from "../../assets/Dragon.gif"
function Loading() {

  return (
    <div className='row '>
        <div className='d-flex align-items-center justify-content-center vh-100'>

      <img src={dragon} alt="dragon" width={500} className='mobile'/>
        </div>
    </div>
  )
}

export default Loading
