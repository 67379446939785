import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
// import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";

import { NavLink } from 'react-router-dom';
import { CgMenuLeft } from 'react-icons/cg';
import { AiOutlineClose } from 'react-icons/ai';
import vrccoinlogo from '../../assets/new_ui/VRC icon.png'

import './Navbar.css'
import Web3Button from "../buttons/Web3Button";
import axios from "axios";
// import { Web3Button } from '@web3modal/react'

const Navigat = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  const [liveRate, setLiveRate] = useState(0);
  const getLivePrice = async () => {
    const { data } = await axios(
      "https://api.coingecko.com/api/v3/simple/price?ids=virtual-coin&vs_currencies=usd"
    );
    setLiveRate(data["virtual-coin"].usd);
  };
  useEffect(()=>{
    getLivePrice()
  },[])

  return (
    <>
      <nav className="app__navbar">
        <div className="app__navbar-logo">
          <NavLink to="/">
            <img src={logo} alt="app__logo" className="img-fluid" width={"150px"} style={{height:'70px'}}  />

          </NavLink>

        </div>
        <ul className="app__navbar-links">
          <NavLink to={"/"}>
            <li className="p__opensans"><a href="#home">Home</a></li>
          </NavLink>
          {/* <NavLink to={"/comming"}>
            <li className="p__opensans"><a href="#about">Comming</a></li>
          </NavLink> */}
          <NavLink to={"/referral"}>
            <li className="p__opensans"><a href="#contact">Referral</a></li>
          </NavLink>
          <NavLink to={"/staking"}>
            <li className="p__opensans"><a href="#about">Staking</a></li>
          </NavLink>
          <NavLink to={"/levels"}>
            <li className="p__opensans"><a href="#awards">Levels</a></li>
          </NavLink>
          <NavLink to={"/withdraw"}>
            <li className="p__opensans"><a href="#menu">Withdraw</a></li>
          </NavLink>
          <NavLink to={"/promotion"}>
            <li className="p__opensans"><a href="#awards">Promotion</a></li>
          </NavLink>
          {/* <NavLink to={"/giveaway"}>
            <li className="p__opensans"><a href="#awards">Giveaway</a></li>
          </NavLink> */}
         
        
          
          {/* <NavLink to={"/contact"}>
            <li className="p__opensans"><a href="#contact">Contact us</a></li>
          </NavLink> */}
        </ul>
        <Nav className="md_btn align-items-center">
          <img src={vrccoinlogo} alt="" style={{height:'30px',marginRight:'3px'}}/>
          <p className="mb-0 color_live mt-1">$ {liveRate}</p>
          <Nav.Link>
            <Web3Button/>
          </Nav.Link>
        </Nav>
        <div className="app__navbar-smallscreen">
        <Nav.Link>
            <Web3Button/>
          </Nav.Link>
          <CgMenuLeft className="mt-1 ms-3" color="#fff" cursor="pointer" fontSize={27} onClick={() => setToggleMenu(true)} />
          {toggleMenu && (
            <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
              <AiOutlineClose fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
              <ul className="app__navbar-smallscreen_links">
                <NavLink to={"/"}>
                  <li><a onClick={() => setToggleMenu(false)}>Home</a></li>
                </NavLink>
                <NavLink to={"/staking"}>
                  <li><a onClick={() => setToggleMenu(false)}>Staking</a></li>
                </NavLink>
                <NavLink to={"/withdraw"}>
                  <li><a onClick={() => setToggleMenu(false)}>WithDraw</a></li>
                </NavLink>
                {/* <NavLink to={"/vesting"}>
                  <li><a onClick={() => setToggleMenu(false)}>Vesting</a></li>
                </NavLink> */}
                <NavLink to={"/levels"}>
                  <li><a onClick={() => setToggleMenu(false)}>Levels</a></li>
                </NavLink>
                <NavLink to={"/referral"}>
                  <li><a onClick={() => setToggleMenu(false)}>Referral</a></li>
                </NavLink>
                <NavLink to={"/promotion"}>
            <li className="p__opensans"><a onClick={() => setToggleMenu(false)}>Promotion</a></li>
          </NavLink>
          {/* <NavLink to={"/giveaway"}>
            <li className="p__opensans"><a onClick={() => setToggleMenu(false)}>Giveaway</a></li>
          </NavLink> */}
                {/* <NavLink to={"/about"}>
                  <li><a onClick={() => setToggleMenu(false)}>About Us</a></li>
                </NavLink>
                <NavLink to={"/contact"}>
                  <li><a onClick={() => setToggleMenu(false)}>Contact Us</a></li>
                </NavLink> */}




              </ul>

            </div>
          )}
        </div>

      </nav>


    </>
  )
}

export default Navigat



