import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react'
import { rpcURL } from '../../config';
import Skeleton from 'react-loading-skeleton';
import { getCurentBlock } from '../../instances/instance';

function BlockNumber({ index, item }) {
    const [blockNumber, setBlockNumber] = useState(null)
    const getBlock = async () => {
        try {
            const block = await getCurentBlock();
            setBlockNumber(index ? block - index : block)
        } catch (error) {
            console.error("error while get current blokc", error);
        }
    }
    useEffect(() => {
        getBlock()
    }, [item])
    return (
        <>
            {
                blockNumber === null ? <Skeleton
                    count={1}
                    inline
                    width={200}
                /> : blockNumber
            }
        </>
    )
}

export default BlockNumber
