export const defaultChain = 713;
export const rpcURL = "https://allrpc.vrcscan.com/"
// export const rpcURL = "https://rpc-mainnet-5.vrcscan.com/"

export function delay(time) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, time);
    });
}

export function computeTimeAgo(unixTimestamp) {
    const secondsPerMinute = 60;
    const secondsPerHour = 60 * secondsPerMinute;
    const secondsPerDay = 24 * secondsPerHour;
    const secondsPerMonth = 30 * secondsPerDay;  // Assuming average month length
    const secondsPerYear = 365 * secondsPerDay;  // Not accounting for leap years

    const currentTime = Math.floor(Date.now() / 1000);  // Current time in seconds
    const elapsedTime = currentTime - unixTimestamp;  // Elapsed time in seconds

    if (elapsedTime < secondsPerMinute) {
        return `${elapsedTime} seconds ago`;
    } else if (elapsedTime < secondsPerHour) {
        return `${Math.floor(elapsedTime / secondsPerMinute)} minutes ago`;
    } else if (elapsedTime < secondsPerDay) {
        return `${Math.floor(elapsedTime / secondsPerHour)} hours ago`;
    } else if (elapsedTime < secondsPerMonth) {
        return `${Math.floor(elapsedTime / secondsPerDay)} days ago`;
    } else if (elapsedTime < secondsPerYear) {
        return `${Math.floor(elapsedTime / secondsPerMonth)} months ago`;
    } else {
        return `${Math.floor(elapsedTime / secondsPerYear)} years ago`;
    }
}

export const usersLevel = [1,2,5,7,10,11,12,13,14,15,16,17,18,19,20];
export const rewardPercentage = [15,12,10,7,5,3,2,2,2,2,2,2,3,3,5];

