import React, { useEffect, useState } from "react";
import { readContract } from "../../../instances/instance";
import { useAccount, useChainId } from "wagmi";
import { defaultChain } from "../../../config";
import Skeleton from "react-loading-skeleton";
import LevelCount from "./LevelCount";

function TotalLevelUser({ index, refresh, totalUsers }) {
  const { address, isConnected } = useAccount();
  const chain = useChainId();
  // const [totalUsers, setTotalUsers] = useState(null)
  // const getTotalUsers = async () => {
  //   try {
  //     const contractInstance = readContract();
  //     let userCount = await contractInstance.userCount(address, index);
  //     setTotalUsers(Number(userCount))
  //   } catch (error) {
  //     console.error("error while get total users", error);
  //   }

  // }
  // useEffect(() => {
  //   if (isConnected && chain === defaultChain)
  //     getTotalUsers()
  // }, [isConnected && chain, address, refresh])
  return (
    <>
      {totalUsers === null ? (
        <Skeleton count={1} inline width={50} />
      ) : (
        totalUsers
      )}
      
    </>
  );
}

export default TotalLevelUser;
