// import React, { useEffect, useState } from 'react'
// import { useAccount, useChainId } from 'wagmi'
// import { readContract } from '../../../instances/instance';
// import { defaultChain } from '../../../config';
// import { formatEther } from 'viem';
// import { Table } from 'react-bootstrap';
// import WithdrawHistoryDetail from './WithdrawHistoryDetail';

// function WithdrawHistory({ refresh }) {
//     const { address, isConnected } = useAccount();
//     const chain = useChainId();
//     const [historyLength, setHisoryLength] = useState(0);
//     const getWithdrawLength = async () => {
//         try {
//             const contractInstance = readContract();
//             let withdrawLength = await contractInstance.withdrawLength(address);
//             setHisoryLength(Number(withdrawLength))
//         } catch (error) {
//             console.error("error while get withdraw length", error);
//         }
//     }
//     useEffect(() => {
//         if (isConnected && chain === defaultChain)
//             getWithdrawLength()
//     }, [isConnected && chain, refresh, address])

//     const [isScrolling, setIsScrolling] = useState(false);

//   const handleScroll = (e) => {
//     const scrollTop = e.currentTarget.scrollTop;
//     setIsScrolling(scrollTop > 0);
//   };
//     return (
//         <div>
//             {historyLength &&
//              <div className="table-responsive" onScroll={handleScroll}>
//             <Table variant="dark" responsive>
//             <thead className={isScrolling ? "table-scrolling" : ""}>
//                     <tr className="table_head">
//                         <th>#</th>
//                         <th>Withdraw Amount</th>
//                         <th>Staking Reward</th>
//                         <th>Level Reward</th>
//                         <th>Wasting</th>
//                         {/* <th>Block</th> */}
//                         <th>Time</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {
//                         [...Array(historyLength).keys()].map((item, index) => {
//                             return <WithdrawHistoryDetail index={index} />
//                         })
//                     }
//                 </tbody>
//             </Table>
//             </div>
//             }
//         </div>
//     )
// }

// export default WithdrawHistory

import React, { useEffect, useState, useCallback } from "react";
import { useAccount } from "wagmi";
import { APIinstance } from "../../../axios.config";
import { formatEther } from "viem";
import { Pagination, Form, Table } from "react-bootstrap";
import { TiArrowUnsorted } from "react-icons/ti";

function WithdrawHistory({ refresh }) {
  const { address, isConnected } = useAccount();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 0,
    siblingCount: 1,
  });
  const [orderDircetion, setOrderDircetion] = useState("asc");

  const [withdrawHistory, setWithdrawHistory] = useState([]);

  const getWithdrawHistoryApi = useCallback(
    async (page, limit) => {
      if (!isConnected) return;
      try {
        const response = await APIinstance.get(
          `withdraw/userWithdraw?userAddress=${address}&page=${page}&limit=${limit}&direction=${orderDircetion}`
        );
        const data = response.data;
        const totalPages = Math.ceil(data.params.total / limit);
        setWithdrawHistory(
          data.withdraws.map((withdraw) => ({
            index: withdraw.index,
            amount: Number(formatEther(withdraw.amount)).toFixed(4),
            stakingReward: Number(
              formatEther(withdraw.investmentReward)
            ).toFixed(4),
            levelReward: Number(formatEther(withdraw.referralReward)).toFixed(
              4
            ),
            vesting: Number(formatEther(withdraw.charityReward)).toFixed(4),
            time: new Date(Number(withdraw.time) * 1000)
              .toISOString()
              .slice(0, 19)
              .replace("T", " "),
          }))
        );
        setPagination((prev) => ({ ...prev, totalPages: totalPages }));
      } catch (error) {
        console.error("Error while getting withdraw history", error);
      }
    },
    [address, isConnected, orderDircetion]
  );

  useEffect(() => {
    getWithdrawHistoryApi(pagination.page, pagination.limit);
  }, [pagination.page, pagination.limit, getWithdrawHistoryApi, refresh]);

  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  let startPage = Math.max(1, pagination.page - pagination.siblingCount);
  let endPage = Math.min(
    pagination.totalPages,
    pagination.page + pagination.siblingCount
  );
  let items = range(startPage, endPage).map((page) => (
    <Pagination.Item
      key={page}
      active={page === pagination.page}
      onClick={() => handlePageChange(page)}
    >
      {page}
    </Pagination.Item>
  ));

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleLimitChange = (e) => {
    const newLimit = Number(e.target.value);
    setPagination((prev) => ({ ...prev, page: 1, limit: newLimit }));
  };

  return (
    <div>
      {isConnected && (
        <>
          <div className="table-responsive">
            <Table variant="dark" responsive>
              <thead>
                <tr className="table_head">
                  <th>
                    #
                    {orderDircetion === "desc" ? (
                      <TiArrowUnsorted
                        className="fs-5"
                        onClick={() => setOrderDircetion("asc")}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <TiArrowUnsorted
                        className="fs-5"
                        onClick={() => setOrderDircetion("desc")}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </th>
                  <th>Withdraw Amount</th>
                  <th>Staking Reward</th>
                  <th>Level Reward</th>
                  <th>Wasting</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {/* {withdrawHistory.map((detail, index) => (
                  <tr key={index}>
                    <td>{Number(detail.index) + 1}</td>
                    <td>{detail.amount}</td>
                    <td>{detail.stakingReward}</td>
                    <td>{detail.levelReward}</td>
                    <td>{detail.vesting}</td>
                    <td>{detail.time}</td>
                  </tr>
                ))} */}
                {withdrawHistory.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center p-3">
                    No records found
                  </td>
                </tr>
              ) : (
                withdrawHistory.map((detail, index) => (
                  <tr key={index}>
                    <td>{Number(detail.index) + 1}</td>
                    <td>{detail.amount}</td>
                    <td>{detail.stakingReward}</td>
                    <td>{detail.levelReward}</td>
                    <td>{detail.vesting}</td>
                    <td>{detail.time}</td>
                  </tr>
                ))
              )}
              </tbody>
            </Table>
          </div>

          <div className="row align-items-center justify-content-center ">
            <div className="col-lg-6 col-6">
              <Form>
                <Form.Group
                  controlId="limitSelect"
                  className="d-lg-flex align-items-center gap-3 custom-select-wrapper"
                >
                  <Form.Label className="change mb-0">
                    Rows per page:
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={pagination.limit}
                    onChange={handleLimitChange}
                    className="custom-select-input"
                    style={{ width: "70px" }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </div>
            <div className="col-lg-6 justify-content-end text-end col-6 mt-4 pt-1">
              <Pagination size="md" className="justify-content-end text-end">
                <Pagination.First
                  disabled={pagination.page === 1}
                  onClick={() => handlePageChange(1)}
                />
                <Pagination.Prev
                  disabled={pagination.page === 1}
                  onClick={() => handlePageChange(pagination.page - 1)}
                />
                {items}
                <Pagination.Next
                  disabled={pagination.page === pagination.totalPages}
                  onClick={() => handlePageChange(pagination.page + 1)}
                />
                <Pagination.Last
                  disabled={pagination.page === pagination.totalPages}
                  onClick={() => handlePageChange(pagination.totalPages)}
                />
              </Pagination>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WithdrawHistory;
